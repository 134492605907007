import { MagnifyingGlass } from "@phosphor-icons/react";
import React, { ChangeEvent, ChangeEventHandler, useEffect, useState } from "react";
import styled from "styled-components";

import { useMutation } from "react-query";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useCloseRef } from "../../hooks/useCloseRef";

const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding-right: 18px;

  form {
    width: 100%;
  }

  .input__wrapper {
    position: relative;
    min-height: 32px;
    width: 100%;
  }

  .input__wrapper input {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }

  .icon {
    position: absolute;
    top: 0;
    left: 8px;
    height: 100%;
    width: fit-content;
    display: flex;
    align-items: center;

    svg {
      color: #3f3f46;
    }
  }

  input {
    background-color: rgba(0, 0, 59, 0.05);
    min-height: 32px;
    padding: 4px 6px;
    font-size: 0.875em;
    padding-left: 32px;
  }

  .results {
    max-height: 70vh;
    position: absolute;
    background-color: white;
    top: calc(100% + 8px);
    left: 0;
    width: 100%;
    border-radius: 6px;
    box-shadow: rgba(1, 1, 46, 0.133) 0px 12px 40px 0px, rgba(0, 8, 47, 0.275) 0px 12px 20px -12px;
    padding: 16px;
    z-index: 1;
    overflow-y: auto;

    .result__item {
      cursor: pointer;
      border-radius: 6px;
      padding: 8px;

      &:hover {
        background-color: #fff1f2;

        h5 {
          color: #fb7185;
          font-weight: 500;
        }
      }

      h5 {
        font-weight: 400;
      }

      p {
        font-size: 12px;
      }
    }
  }
`;

const Search = () => {
  const [searchState, setSearchState] = useState<string | undefined>();
  const [results, setResults] = useState<[]>([]);
  const navigate = useNavigate();
  const [show, setShow] = useState<boolean>(false);
  const [ref] = useCloseRef(() => setShow(false));
  const [loading, setLoading] = useState(false);

  const mutation = useMutation({
    mutationFn: async (searchString: string) => {
      const { data } = await axios.get(`https://cms.pxhub.io/api/documentations?filters[$or][0][doc][title][$containsi]=${searchString}&filters[$or][0][doc][body][$containsi]=${searchString}&populate[0]=doc`);

      const foundDocs = data.data.map((item: any) => {
        const docs = item.attributes.doc.map((doc: any) => ({
          title: doc.title,
          description: doc.description,
          pageRoute: `docs/${item.attributes.slug}/${doc.url_slug}`,
          prettyPath: `${item.attributes.section_header} > ${doc.title}`,
        }));
        return docs;
      });

      setResults(foundDocs.flat());
      setLoading(false);
    },
  });

  const handleSearchChange = (e: ChangeEvent<any>) => {
    setShow(true);
    setLoading(true);
    setSearchState(e.target.value);
  };

  const handleClick = (path: string) => {
    setShow(false);
    setResults([]);
    setSearchState(undefined);
    navigate(path);
  };

  useEffect(() => {
    if (!searchState) {
      setShow(false);
      setResults([]);
      setLoading(false);
      return;
    }
    const delayDebounceFn = setTimeout(async () => {
      mutation.mutate(searchState);
    }, 1000);
    return () => clearTimeout(delayDebounceFn);
  }, [searchState]);

  return (
    <SearchWrapper>
      <div className="input__wrapper">
        <input type="text" name="search" placeholder="Search the docs..." onChange={handleSearchChange} value={searchState} />
        {show ? (
          <div ref={ref} className="results">
            {loading ? (
              <div className="result__item row flex-column middle-xs">
                <div className="loading"></div>
              </div>
            ) : results.length ? (
              results.map((item: any) => (
                <div className="result__item row flex-column" onClick={() => handleClick(`/${item.pageRoute}`)}>
                  <h5 className="mb-8">{item.title}</h5>
                  <p>{item.prettyPath}</p>
                </div>
              ))
            ) : (
              <div className="result__item row flex-column">
                <h5>No results found</h5>
              </div>
            )}
          </div>
        ) : null}
        <div className="icon">
          <MagnifyingGlass />
        </div>
      </div>
    </SearchWrapper>
  );
};

export default Search;
