import React, { Fragment, useState } from "react";
import { NavLink } from "react-router-dom";
import { useCategory, useHeader, usePost } from "../../react-query/actions/useHeaders";
import { CSSTransition } from "react-transition-group";
import { useAppStore } from "../../store/store";
import FindIcon from "../../utils/FindIcon";

interface Props {
  isActive: boolean;
  toggleActive: (arg: boolean) => void;
  slug: string;
}

const Navigation: React.FC<Props> = ({ isActive, toggleActive, slug }: Props) => {
  const { data, isLoading, isSuccess } = useCategory(slug);

  const isSessionActive = useAppStore((state) => state.session.isSessionActive);

  return !isLoading ? (
    <Fragment>
      <CSSTransition in={isActive} classNames="my-node" timeout={500}>
        <div className="dark__background"></div>
      </CSSTransition>
      <div className={isActive ? "navigation__container active" : "navigation__container"}>
        <nav>
          <ul>
            {data &&
              data
                .filter((item: any) => {
                  if (isSessionActive) return true;
                  else return isSessionActive === item.attributes.authed;
                })
                .map((item: any, i: any) => (
                  <li key={i}>
                    <h5 className="nav__section__header bold">{item.attributes.section_header}</h5>
                    <ul>
                      {item.attributes.doc.map((ob: any, i: any) => (
                        <li onClick={() => toggleActive(false)} key={i}>
                          <NavLink className={({ isActive }) => (isActive ? "active" : undefined)} to={`/docs/${item.attributes.slug}/${ob.url_slug}`}>
                            <span className="icon">{FindIcon(item.icon)}</span>
                            {ob.title}
                          </NavLink>
                        </li>
                      ))}
                    </ul>
                  </li>
                ))}
          </ul>
        </nav>
      </div>
    </Fragment>
  ) : (
    <div className={isActive ? "navigation__container active" : "navigation__container"}></div>
  );
};

export default Navigation;
