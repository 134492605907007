import React from "react";

import styled from "styled-components";

interface Props {
  headings: string[];
}

const StyledAnchorHeadings = styled.div`
  ul {
    gap: 16px;
  }
  ul li {
    list-style-type: none;
    font-size: 14px;
    cursor: pointer;
    color: #475569;
  }
`;

const AnchorHeading = ({ headings }: Props) => {
  const handleHeadingClick = (item: string) => {
    // 1. Identify the element containing the string
    const targetElement = Array.from(document.querySelectorAll("h1, h2, h3, h4")).find((element) => {
      if (!element) return false;
      //@ts-ignore
      return element.innerText.includes(item);
    });

    // 2. Get the position of that element relative to the document
    if (targetElement) {
      const targetPosition = targetElement.getBoundingClientRect().top + window.pageYOffset;

      // 3. Scroll the document to that position
      window.scrollTo({
        top: targetPosition,
        behavior: "smooth", // Optionally, use smooth scrolling
      });
    }
  };

  return (
    <div className="row flex-column anchor">
      <h5 className="bold mt-36 mb-16">Whats on this page</h5>
      <StyledAnchorHeadings>
        <ul className="row flex-column">
          {headings.map((item, i) => (
            <li onClick={() => handleHeadingClick(item)} key={i}>
              {item}
            </li>
          ))}
        </ul>
      </StyledAnchorHeadings>
    </div>
  );
};

export default AnchorHeading;
