import { useQuery } from "react-query";
import axios, { AxiosResponse } from "axios";
import { useAppStore } from "../../store/store";
import { IDoc, IStrapiDoc } from "../../interfaces";

const isActive = useAppStore.getState().session.isSessionActive;

export const useHeader = () =>
  useQuery(
    "headers",
    async () => {
      const { data } = await axios.get("https://cms.pxhub.io/api/documentations?populate=*");

      return data.data;
    },
    { refetchOnWindowFocus: false }
  );

export const useCategory = (slug: string) =>
  useQuery(
    ["category", { slug }],
    async () => {
      const { data } = await axios.get(`https://cms.pxhub.io/api/documentations?filters[slug][$eq]=${slug}&populate=*`);

      return data.data;
    },
    { refetchOnWindowFocus: false }
  );

// ${!isActive ? `&filters[authed][$eq]=false` : ""

export const usePost = (slug: string, pageSlug: string | undefined) =>
  useQuery(
    ["posts", { slug, pageSlug }],
    async () => {
      const { data } = (await axios.get(`https://cms.pxhub.io/api/documentations?filters[slug][$eq]=${slug}&populate[0]=doc&populate[1]=doc.blog_user&populate[2]=doc.blog_user.profile_picture`)) as AxiosResponse<IStrapiDoc>;

      if (!data.data.length) return { doc: [] };

      let returnDoc: IDoc[];

      if (!pageSlug) returnDoc = [data.data[0].attributes.doc[0]];
      else {
        const found = data.data[0].attributes.doc.find((item: any) => item?.url_slug === pageSlug);
        if (found) returnDoc = [found];
        else returnDoc = [];
      }

      return {
        doc: returnDoc,
        requireAuth: data.data[0].attributes.authed || false,
      };
    },
    { refetchOnWindowFocus: false }
  );

export const useIntro = (id: number) =>
  useQuery("intro", async () => {
    const { data } = await axios.get(`https://cms.pxhub.io/api/documentations?filters[id][$eq]=1&populate=*`);

    return data.data[0];
  });

export const useGetDocumentationCategories = () =>
  useQuery(
    "documentationCategories",
    async () => {
      const { data } = (await axios.get("https://cms.pxhub.io/api/documentations?populate=icon_image&sort[1]=doc_order")) as AxiosResponse<{
        data: {
          attributes: {
            section_header: string;
            slug: string;
            authed: boolean;
            section_description: string | null;
            icon_image: {
              data: {
                attributes: {
                  url: string | null;
                };
              };
            };
          };
        }[];
      }>;

      return data.data;
    },
    { refetchOnWindowFocus: false }
  );

export const useSearch = (searchString: string) =>
  useQuery(
    "docSearch",
    async () => {
      const { data } = await axios.get(`https://cms.pxhub.io/api/documentations?filters[$or][0][doc][title][$containsi]=${searchString}&filters[$or][0][doc][body][$containsi]=${searchString}&populate[0]=doc`);

      const foundDocs = data.data.map((item: any) => {
        const docs = item.attributes.doc.map((doc: any) => ({
          title: doc.title,
          description: doc.description,
          pageRoute: `${item.attributes.slug}/${doc.url_slug}
      `,
        }));
      });
    },
    { refetchOnWindowFocus: false }
  );
