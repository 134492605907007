import React, { useEffect, useRef } from "react";

export const useCloseRef = (callback: () => void): React.MutableRefObject<any>[] => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleCloseAll = (e: MouseEvent) => {
      if (e.button === 2 || e.buttons === 2) return;
      if (!ref.current || ref.current.contains(e.target as Node)) {
        return;
      }

      callback();
    };

    document.addEventListener("mousedown", handleCloseAll);
    return () => document.removeEventListener("mousedown", handleCloseAll);
  }, []);

  return [ref];
};
