import { default as baseAxios } from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import { useRefreshToken } from "../react-query/actions/useAuth";
import { useAppStore } from "../store/store";
import cookies from "js-cookie";

const setSessionActive = useAppStore.getState().setSessionActive;

const baseHeaders = {
  Accept: "*/*",
  "Content-Type": "application/json",
  "Accept-Language": "en-US",
};

export const axios = baseAxios.create({ headers: baseHeaders });

const refreshAuthLogic = async (failedRequest: any) => {
  try {
    const res = await axios.post(`${process.env.REACT_APP_AUTH_V1}/v1/refresh_token`, {}, { withCredentials: true });

    const accessToken = `Bearer ${cookies.get("pxhub_access_token")}`;

    failedRequest.response.config.headers["Authorization"] = accessToken;
    return Promise.resolve();
  } catch (err: any) {
    setSessionActive(false);
  }
};

axios.interceptors.request.use(
  (config) => {
    const token = cookies.get("pxhub_access_token");

    if (token) {
      config.headers!["Authorization"] = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);

createAuthRefreshInterceptor(axios, refreshAuthLogic);
