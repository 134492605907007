import { useNavigate, useParams } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import { usePost } from "../../react-query/actions/useHeaders";
import rehypeRaw from "rehype-raw";
import Navigation from "../navigation/Navigation";
import DocHeading from "../docHeading/DocHeading";

import { useAppStore } from "../../store/store";
import { useEffect, useState } from "react";
import AnchorHeading from "../anchorHeading/AnchorHeading";
import { toast } from "sonner";
const gfm = require("remark-gfm");

interface Props {
  hamNavActive: boolean;
  handleSetMobileNavigation: () => void;
}

const ContentView: React.FC<Props> = ({ hamNavActive, handleSetMobileNavigation }: Props) => {
  // Get the page slug
  const { slug, pageSlug } = useParams<{ slug: string; pageSlug?: string }>();
  const navigate = useNavigate();

  // Fetch data
  const { data, isLoading, isSuccess } = usePost(String(slug), pageSlug);

  const [htmlString, setHtmlString] = useState(data?.doc[0].body); // State to store the HTML string

  const [headingsArray, setHeadingsArray] = useState<string[]>([]); // State to store the headings array

  const isSessionActive = useAppStore().session.isSessionActive;

  useEffect(() => {
    if (data && isSuccess && data.requireAuth && !isSessionActive) {
      toast.warning("Please Sign In to view this page");
      navigate("/docs");
    }
    setHtmlString(data?.doc[0].body);
  }, [data]);

  useEffect(() => {
    if (!htmlString) return setHeadingsArray([]);

    // Create a new DOMParser instance
    const parser = new DOMParser();

    // Parse the HTML string into a DOM document
    const doc = parser.parseFromString(htmlString, "text/html");

    // Select all headings (h1, h2, h3, h4) from the parsed DOM document
    const allHeadings = ["h1", "h2", "h3", "h4"].flatMap((headingTag) => Array.from(doc.querySelectorAll(headingTag)));

    // Extract text content from each heading and store them in an array while preserving order
    //@ts-ignore
    const headingsContentArray = allHeadings.map((heading) => heading.textContent.trim());

    // Set the headings array in state
    //@ts-ignore
    setHeadingsArray(headingsContentArray);
  }, [htmlString]);

  return (
    <>
      <Navigation isActive={hamNavActive} toggleActive={handleSetMobileNavigation} slug={slug!} />

      {!isLoading && isSuccess && data ? (
        <div className="content__view">
          <div className="markdown-body">
            <DocHeading heading={data.doc[0].title} description={data.doc[0].description} author={data.doc[0].blog_user} />
            {data && data.doc.length ? (
              <ReactMarkdown children={data.doc[0].body} remarkPlugins={[gfm]} rehypePlugins={[rehypeRaw]} transformImageUri={(uri: string) => (uri.startsWith("http") ? uri : `https://cms.pxhub.io${uri}`)} transformLinkUri={null} />
            ) : null}
          </div>
        </div>
      ) : null}

      <AnchorHeading headings={headingsArray} />
    </>
  );
};

export default ContentView;
