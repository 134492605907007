import React, { Fragment } from "react";
import { capitalCase } from "change-case";
import { Link, useLocation } from "react-router-dom";
import { CaretRight } from "@phosphor-icons/react";

const BreadCrumbs = () => {
  const { pathname } = useLocation();

  const generateBreadCrumb = (pathname: string) => {
    let paths = pathname.split("/");

    // remove the last element if there was a / at the end of the pathname
    paths = paths[paths.length - 1] === "" ? paths.slice(0, paths.length - 1) : paths;

    // remove the first element if the second one is an empty string which means that we are in the root of the website
    paths = paths[1] === "" ? paths.slice(1) : paths;

    const breadcrumb = paths.map((path, index) => {
      // The first element should receive the <IndexLink> React element
      if (index === 0) {
        return (
          <Fragment key={index}>
            <li>
              <Link to="/docs">{capitalCase(path.replace(/-/g, " "))}</Link>
            </li>
          </Fragment>
        );
      } else {
        // Build the path for the current URL
        const url = paths.slice(0, index + 1).join("/");

        // HTML structure for every link except the first
        return (
          <Fragment key={index}>
            <li>
              <Link to={url}>{capitalCase(path.replace(/-/g, " "))}</Link>
            </li>
            <CaretRight size="16" weight="bold" />
          </Fragment>
        );
      }
    });

    // Return a list of links
    return breadcrumb;
  };

  return (
    <div className="inner_container bread">
      <ul className="bread_crumbs">{generateBreadCrumb(pathname)}</ul>
    </div>
  );
};

export default BreadCrumbs;
