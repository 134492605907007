import React, { useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Logo from "../../images/logo.png";
import Search from "./Search";
import LoginPopup from "../login/LoginPopup";
import { useAppStore } from "../../store/store";
import { axios } from "../../utils/axiosLib";

interface Props {
  toggleActive: () => void;
}

const Header: React.FC<Props> = ({ toggleActive }: Props) => {
  const { pathname } = useLocation();
  const [showModal, setShowModal] = useState<boolean>(false);
  const toggleModal = () => setShowModal((state) => !state);
  const isSessionActive = useAppStore((state) => state.session.isSessionActive);
  const { setSessionActive } = useAppStore();
  const navigate = useNavigate();

  const handleLogout = async (e: any) => {
    e.preventDefault();
    try {
      await axios.post(`${process.env.REACT_APP_AUTH_V1}/v1/logout`, {
        withCredentials: true,
      });
      setSessionActive(false);
      navigate("/");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <header>
        <div className="row header__content container g-8 no-wrap middle-xs">
          <div className="company__logo">
            <Link to="/">
              <img src={Logo} alt="The People Experience Hub" />
            </Link>
            <Link to="/">
              <span>The People Experience Hub Docs</span>
            </Link>
          </div>
          <Search />
          {!isSessionActive ? (
            <button className="cta" type="button" onClick={toggleModal}>
              Login
            </button>
          ) : (
            <button className="cta" type="button" onClick={handleLogout}>
              Sign Out
            </button>
          )}
          {pathname !== "/docs" ? (
            <div onClick={() => toggleActive()} className="ham__nav">
              <span></span>
              <span></span>
              <span></span>
            </div>
          ) : null}
        </div>
      </header>
      {showModal ? <LoginPopup closePopUp={toggleModal} /> : null}
    </>
  );
};

export default Header;
