import React from "react";
import styled from "styled-components";
import { IAuthor } from "../../interfaces";
import BreadCrumbs from "../breadCrumbs";

const HeadingWrapper = styled.div`
  .written_by .profile_picture {
    height: 42px;
    width: 42px;
    border-radius: 50%;
    background-size: contain;
    background-position: center;
  }

  .heading p {
    font-size: 1.125em;
    line-height: 24px;
  }

  .written_by .author p {
    margin-bottom: 0px;
  }
`;

interface Props {
  heading: string;
  description: string | null;
  author: IAuthor;
}

const DocHeading: React.FC<Props> = ({ heading, description, author }: Props) => {
  const getImageString = (path: string) => {
    return path.startsWith("http") ? path : `https://cms.pxhub.io${path}`;
  };

  return (
    <HeadingWrapper className="mt-32">
      <div className="row mb-4">
        <BreadCrumbs />
      </div>
      <div className="heading mb-16">
        <h1 className="mb-8">{heading}</h1>
        <p>{description}</p>
      </div>
      <div className="written_by">
        <div className="row middle-xs g-8">
          <div className="profile_picture" style={{ backgroundImage: `url(${getImageString(author.data?.attributes?.profile_picture.data.attributes.url || "")}` }}></div>
          <div className="author">
            <p>Written By {author.data?.attributes?.user_name}</p>
          </div>
        </div>
      </div>
      <hr className="sep mt-16" />
    </HeadingWrapper>
  );
};

export default DocHeading;
