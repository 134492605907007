import { create } from "zustand";
import { persist, devtools } from "zustand/middleware";

import { ISessionSlice, createSessionSlice } from "./slices/SessionSlice";

export type StoreState = ISessionSlice;

export const useAppStore = create<StoreState>()(
  devtools(
    persist(
      (...a) => ({
        ...createSessionSlice(...a),
      }),
      { name: "doc-store" }
    )
  )
);
