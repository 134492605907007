import React from "react";
import Header from "../components/header/Header";
import { Outlet } from "react-router-dom";
import { Toaster } from "sonner";

interface Props {
  toggleActive: any;
}

const Default = ({ toggleActive }: Props) => {
  return (
    <>
      <Toaster richColors position="bottom-center" expand />
      <Header toggleActive={toggleActive} />
      <div className="app__container">
        <Outlet />
      </div>
    </>
  );
};

export default Default;
