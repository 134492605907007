import React, { useState } from "react";
import { useQuery } from "react-query";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Loading from "../components/loading/Loading";
import ContentView from "../components/views/ContentView";

import { axios } from "../utils/axiosLib";
import { useAppStore } from "../store/store";
import Home from "../pages/Home";
import Default from "../roots/Default";

const AppRouter = () => {
  const [hamNavActive, setHamNavActive] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const handleSetMobileNavigation = (arg: boolean = !hamNavActive) => setHamNavActive(arg);
  const { setSessionActive } = useAppStore();

  useQuery(
    "getSession",
    async () => {
      setLoading(true);
      try {
        const { data } = await axios.get(`${process.env.REACT_APP_AUTH_V1}/v1/user`);
        setSessionActive(true);
        setLoading(false);
        return data;
      } catch (error) {
        setLoading(false);
        setSessionActive(false);
      }
    },
    { refetchOnWindowFocus: false }
  );

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Default toggleActive={handleSetMobileNavigation} />,
      children: [
        { path: "", element: <Home /> },
        {
          path: "docs",

          children: [
            { path: "", element: <Home /> },
            {
              path: ":slug",
              element: <ContentView hamNavActive={hamNavActive} handleSetMobileNavigation={handleSetMobileNavigation} />,
              children: [{ path: ":pageSlug", element: <ContentView hamNavActive={hamNavActive} handleSetMobileNavigation={handleSetMobileNavigation} /> }],
            },
          ],
        },
      ],
    },
  ]);

  return isLoading ? <Loading /> : <RouterProvider router={router} />;
};

export default AppRouter;
