import React from "react";
import { useGetDocumentationCategories } from "../react-query/actions/useHeaders";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Lifebuoy } from "@phosphor-icons/react";
import { useAppStore } from "../store/store";

const ItemWrapper = styled.div`
  width: 100%;
  border: 1px solid #e2e8f0;
  padding: 24px;
  border-radius: 6px;
  background-color: white;
  height: 100%;

  &:hover {
    border: 1px solid #94a3b8;
  }

  .icon {
    height: 36px;
    width: 36px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      color: black;
      font-size: 1.5em;
    }
  }
`;

// Need to get categories

const Home = () => {
  const { data } = useGetDocumentationCategories();

  return (
    <div className="container row flex-column">
      <div className="row">
        <div className="col-xs-12 mb-16 mt-32">
          <h2>Documentation</h2>
        </div>
      </div>
      <div className="row">
        {data &&
          data.map((item, i) => {
            return (
              <div className="col-md-6 col-xs-12 p-16">
                <Link key={i} to={`/docs/${item.attributes.slug}`}>
                  <ItemWrapper>
                    <div className="icon mb-8">
                      <Lifebuoy />
                    </div>
                    <div className="heading row mb-8">
                      <h3>{item.attributes.section_header}</h3>
                    </div>
                    <div className="helper__text">
                      <p>{item.attributes.section_description}</p>
                    </div>
                  </ItemWrapper>
                </Link>
              </div>
            );
          })}
        <div className="col-md-6 col-xs-12 p-16">
          <a href="https://pxhub.io/contact">
            <ItemWrapper>
              <div className="icon mb-16">
                <Lifebuoy />
              </div>
              <div className="heading row mb-8">
                <h3>Contact Us</h3>
              </div>
              <div className="helper__text">
                <p>Send us a message here</p>
              </div>
            </ItemWrapper>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Home;
