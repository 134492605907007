import React from "react";
import * as PhosphorIcons from "phosphor-react";

function FindIcon(iconName: string) {
  switch (iconName) {
    case "Activity":
      return <PhosphorIcons.Activity />;
    case "AddressBook":
      return <PhosphorIcons.AddressBook />;
    case "Airplane":
      return <PhosphorIcons.Airplane />;
    case "AirplaneInFlight":
      return <PhosphorIcons.AirplaneInFlight />;
    case "AirplaneLanding":
      return <PhosphorIcons.AirplaneLanding />;
    case "AirplaneTakeoff":
      return <PhosphorIcons.AirplaneTakeoff />;
    case "AirplaneTilt":
      return <PhosphorIcons.AirplaneTilt />;
    case "Airplay":
      return <PhosphorIcons.Airplay />;
    case "Alarm":
      return <PhosphorIcons.Alarm />;
    case "Alien":
      return <PhosphorIcons.Alien />;
    case "AlignBottom":
      return <PhosphorIcons.AlignBottom />;
    case "AlignBottomSimple":
      return <PhosphorIcons.AlignBottomSimple />;
    case "AlignCenterHorizontal":
      return <PhosphorIcons.AlignCenterHorizontal />;
    case "AlignCenterHorizontalSimple":
      return <PhosphorIcons.AlignCenterHorizontalSimple />;
    case "AlignCenterVertical":
      return <PhosphorIcons.AlignCenterVertical />;
    case "AlignCenterVerticalSimple":
      return <PhosphorIcons.AlignCenterVerticalSimple />;
    case "AlignLeft":
      return <PhosphorIcons.AlignLeft />;
    case "AlignLeftSimple":
      return <PhosphorIcons.AlignLeftSimple />;
    case "AlignRight":
      return <PhosphorIcons.AlignRight />;
    case "AlignRightSimple":
      return <PhosphorIcons.AlignRightSimple />;
    case "AlignTop":
      return <PhosphorIcons.AlignTop />;
    case "AlignTopSimple":
      return <PhosphorIcons.AlignTopSimple />;
    case "Anchor":
      return <PhosphorIcons.Anchor />;
    case "AnchorSimple":
      return <PhosphorIcons.AnchorSimple />;
    case "AndroidLogo":
      return <PhosphorIcons.AndroidLogo />;
    case "AngularLogo":
      return <PhosphorIcons.AngularLogo />;
    case "Aperture":
      return <PhosphorIcons.Aperture />;
    case "AppStoreLogo":
      return <PhosphorIcons.AppStoreLogo />;
    case "AppWindow":
      return <PhosphorIcons.AppWindow />;
    case "AppleLogo":
      return <PhosphorIcons.AppleLogo />;
    case "ApplePodcastsLogo":
      return <PhosphorIcons.ApplePodcastsLogo />;
    case "Archive":
      return <PhosphorIcons.Archive />;
    case "ArchiveBox":
      return <PhosphorIcons.ArchiveBox />;
    case "ArchiveTray":
      return <PhosphorIcons.ArchiveTray />;
    case "Armchair":
      return <PhosphorIcons.Armchair />;
    case "ArrowArcLeft":
      return <PhosphorIcons.ArrowArcLeft />;
    case "ArrowArcRight":
      return <PhosphorIcons.ArrowArcRight />;
    case "ArrowBendDoubleUpLeft":
      return <PhosphorIcons.ArrowBendDoubleUpLeft />;
    case "ArrowBendDoubleUpRight":
      return <PhosphorIcons.ArrowBendDoubleUpRight />;
    case "ArrowBendDownLeft":
      return <PhosphorIcons.ArrowBendDownLeft />;
    case "ArrowBendDownRight":
      return <PhosphorIcons.ArrowBendDownRight />;
    case "ArrowBendLeftDown":
      return <PhosphorIcons.ArrowBendLeftDown />;
    case "ArrowBendLeftUp":
      return <PhosphorIcons.ArrowBendLeftUp />;
    case "ArrowBendRightDown":
      return <PhosphorIcons.ArrowBendRightDown />;
    case "ArrowBendRightUp":
      return <PhosphorIcons.ArrowBendRightUp />;
    case "ArrowBendUpLeft":
      return <PhosphorIcons.ArrowBendUpLeft />;
    case "ArrowBendUpRight":
      return <PhosphorIcons.ArrowBendUpRight />;
    case "ArrowCircleDown":
      return <PhosphorIcons.ArrowCircleDown />;
    case "ArrowCircleDownLeft":
      return <PhosphorIcons.ArrowCircleDownLeft />;
    case "ArrowCircleDownRight":
      return <PhosphorIcons.ArrowCircleDownRight />;
    case "ArrowCircleLeft":
      return <PhosphorIcons.ArrowCircleLeft />;
    case "ArrowCircleRight":
      return <PhosphorIcons.ArrowCircleRight />;
    case "ArrowCircleUp":
      return <PhosphorIcons.ArrowCircleUp />;
    case "ArrowCircleUpLeft":
      return <PhosphorIcons.ArrowCircleUpLeft />;
    case "ArrowCircleUpRight":
      return <PhosphorIcons.ArrowCircleUpRight />;
    case "ArrowClockwise":
      return <PhosphorIcons.ArrowClockwise />;
    case "ArrowCounterClockwise":
      return <PhosphorIcons.ArrowCounterClockwise />;
    case "ArrowDown":
      return <PhosphorIcons.ArrowDown />;
    case "ArrowDownLeft":
      return <PhosphorIcons.ArrowDownLeft />;
    case "ArrowDownRight":
      return <PhosphorIcons.ArrowDownRight />;
    case "ArrowElbowDownLeft":
      return <PhosphorIcons.ArrowElbowDownLeft />;
    case "ArrowElbowDownRight":
      return <PhosphorIcons.ArrowElbowDownRight />;
    case "ArrowElbowLeft":
      return <PhosphorIcons.ArrowElbowLeft />;
    case "ArrowElbowLeftDown":
      return <PhosphorIcons.ArrowElbowLeftDown />;
    case "ArrowElbowLeftUp":
      return <PhosphorIcons.ArrowElbowLeftUp />;
    case "ArrowElbowRight":
      return <PhosphorIcons.ArrowElbowRight />;
    case "ArrowElbowRightDown":
      return <PhosphorIcons.ArrowElbowRightDown />;
    case "ArrowElbowRightUp":
      return <PhosphorIcons.ArrowElbowRightUp />;
    case "ArrowElbowUpLeft":
      return <PhosphorIcons.ArrowElbowUpLeft />;
    case "ArrowElbowUpRight":
      return <PhosphorIcons.ArrowElbowUpRight />;
    case "ArrowFatDown":
      return <PhosphorIcons.ArrowFatDown />;
    case "ArrowFatLeft":
      return <PhosphorIcons.ArrowFatLeft />;
    case "ArrowFatLineDown":
      return <PhosphorIcons.ArrowFatLineDown />;
    case "ArrowFatLineLeft":
      return <PhosphorIcons.ArrowFatLineLeft />;
    case "ArrowFatLineRight":
      return <PhosphorIcons.ArrowFatLineRight />;
    case "ArrowFatLineUp":
      return <PhosphorIcons.ArrowFatLineUp />;
    case "ArrowFatLinesDown":
      return <PhosphorIcons.ArrowFatLinesDown />;
    case "ArrowFatLinesLeft":
      return <PhosphorIcons.ArrowFatLinesLeft />;
    case "ArrowFatLinesRight":
      return <PhosphorIcons.ArrowFatLinesRight />;
    case "ArrowFatLinesUp":
      return <PhosphorIcons.ArrowFatLinesUp />;
    case "ArrowFatRight":
      return <PhosphorIcons.ArrowFatRight />;
    case "ArrowFatUp":
      return <PhosphorIcons.ArrowFatUp />;
    case "ArrowLeft":
      return <PhosphorIcons.ArrowLeft />;
    case "ArrowLineDown":
      return <PhosphorIcons.ArrowLineDown />;
    case "ArrowLineDownLeft":
      return <PhosphorIcons.ArrowLineDownLeft />;
    case "ArrowLineDownRight":
      return <PhosphorIcons.ArrowLineDownRight />;
    case "ArrowLineLeft":
      return <PhosphorIcons.ArrowLineLeft />;
    case "ArrowLineRight":
      return <PhosphorIcons.ArrowLineRight />;
    case "ArrowLineUp":
      return <PhosphorIcons.ArrowLineUp />;
    case "ArrowLineUpLeft":
      return <PhosphorIcons.ArrowLineUpLeft />;
    case "ArrowLineUpRight":
      return <PhosphorIcons.ArrowLineUpRight />;
    case "ArrowRight":
      return <PhosphorIcons.ArrowRight />;
    case "ArrowSquareDown":
      return <PhosphorIcons.ArrowSquareDown />;
    case "ArrowSquareDownLeft":
      return <PhosphorIcons.ArrowSquareDownLeft />;
    case "ArrowSquareDownRight":
      return <PhosphorIcons.ArrowSquareDownRight />;
    case "ArrowSquareIn":
      return <PhosphorIcons.ArrowSquareIn />;
    case "ArrowSquareLeft":
      return <PhosphorIcons.ArrowSquareLeft />;
    case "ArrowSquareOut":
      return <PhosphorIcons.ArrowSquareOut />;
    case "ArrowSquareRight":
      return <PhosphorIcons.ArrowSquareRight />;
    case "ArrowSquareUp":
      return <PhosphorIcons.ArrowSquareUp />;
    case "ArrowSquareUpLeft":
      return <PhosphorIcons.ArrowSquareUpLeft />;
    case "ArrowSquareUpRight":
      return <PhosphorIcons.ArrowSquareUpRight />;
    case "ArrowUDownLeft":
      return <PhosphorIcons.ArrowUDownLeft />;
    case "ArrowUDownRight":
      return <PhosphorIcons.ArrowUDownRight />;
    case "ArrowULeftDown":
      return <PhosphorIcons.ArrowULeftDown />;
    case "ArrowULeftUp":
      return <PhosphorIcons.ArrowULeftUp />;
    case "ArrowURightDown":
      return <PhosphorIcons.ArrowURightDown />;
    case "ArrowURightUp":
      return <PhosphorIcons.ArrowURightUp />;
    case "ArrowUUpLeft":
      return <PhosphorIcons.ArrowUUpLeft />;
    case "ArrowUUpRight":
      return <PhosphorIcons.ArrowUUpRight />;
    case "ArrowUp":
      return <PhosphorIcons.ArrowUp />;
    case "ArrowUpLeft":
      return <PhosphorIcons.ArrowUpLeft />;
    case "ArrowUpRight":
      return <PhosphorIcons.ArrowUpRight />;
    case "ArrowsClockwise":
      return <PhosphorIcons.ArrowsClockwise />;
    case "ArrowsCounterClockwise":
      return <PhosphorIcons.ArrowsCounterClockwise />;
    case "ArrowsDownUp":
      return <PhosphorIcons.ArrowsDownUp />;
    case "ArrowsHorizontal":
      return <PhosphorIcons.ArrowsHorizontal />;
    case "ArrowsIn":
      return <PhosphorIcons.ArrowsIn />;
    case "ArrowsInCardinal":
      return <PhosphorIcons.ArrowsInCardinal />;
    case "ArrowsInLineHorizontal":
      return <PhosphorIcons.ArrowsInLineHorizontal />;
    case "ArrowsInLineVertical":
      return <PhosphorIcons.ArrowsInLineVertical />;
    case "ArrowsInSimple":
      return <PhosphorIcons.ArrowsInSimple />;
    case "ArrowsLeftRight":
      return <PhosphorIcons.ArrowsLeftRight />;
    case "ArrowsOut":
      return <PhosphorIcons.ArrowsOut />;
    case "ArrowsOutCardinal":
      return <PhosphorIcons.ArrowsOutCardinal />;
    case "ArrowsOutLineHorizontal":
      return <PhosphorIcons.ArrowsOutLineHorizontal />;
    case "ArrowsOutLineVertical":
      return <PhosphorIcons.ArrowsOutLineVertical />;
    case "ArrowsOutSimple":
      return <PhosphorIcons.ArrowsOutSimple />;
    case "ArrowsVertical":
      return <PhosphorIcons.ArrowsVertical />;
    case "Article":
      return <PhosphorIcons.Article />;
    case "ArticleMedium":
      return <PhosphorIcons.ArticleMedium />;
    case "ArticleNyTimes":
      return <PhosphorIcons.ArticleNyTimes />;
    case "Asterisk":
      return <PhosphorIcons.Asterisk />;
    case "AsteriskSimple":
      return <PhosphorIcons.AsteriskSimple />;
    case "At":
      return <PhosphorIcons.At />;
    case "Atom":
      return <PhosphorIcons.Atom />;
    case "Baby":
      return <PhosphorIcons.Baby />;
    case "Backpack":
      return <PhosphorIcons.Backpack />;
    case "Backspace":
      return <PhosphorIcons.Backspace />;
    case "Bag":
      return <PhosphorIcons.Bag />;
    case "BagSimple":
      return <PhosphorIcons.BagSimple />;
    case "Balloon":
      return <PhosphorIcons.Balloon />;
    case "Bandaids":
      return <PhosphorIcons.Bandaids />;
    case "Bank":
      return <PhosphorIcons.Bank />;
    case "Barbell":
      return <PhosphorIcons.Barbell />;
    case "Barcode":
      return <PhosphorIcons.Barcode />;
    case "Barricade":
      return <PhosphorIcons.Barricade />;
    case "Baseball":
      return <PhosphorIcons.Baseball />;
    case "Basketball":
      return <PhosphorIcons.Basketball />;
    case "Bathtub":
      return <PhosphorIcons.Bathtub />;
    case "BatteryCharging":
      return <PhosphorIcons.BatteryCharging />;
    case "BatteryChargingVertical":
      return <PhosphorIcons.BatteryChargingVertical />;
    case "BatteryEmpty":
      return <PhosphorIcons.BatteryEmpty />;
    case "BatteryFull":
      return <PhosphorIcons.BatteryFull />;
    case "BatteryHigh":
      return <PhosphorIcons.BatteryHigh />;
    case "BatteryLow":
      return <PhosphorIcons.BatteryLow />;
    case "BatteryMedium":
      return <PhosphorIcons.BatteryMedium />;
    case "BatteryPlus":
      return <PhosphorIcons.BatteryPlus />;
    case "BatteryWarning":
      return <PhosphorIcons.BatteryWarning />;
    case "BatteryWarningVertical":
      return <PhosphorIcons.BatteryWarningVertical />;
    case "Bed":
      return <PhosphorIcons.Bed />;
    case "BeerBottle":
      return <PhosphorIcons.BeerBottle />;
    case "BehanceLogo":
      return <PhosphorIcons.BehanceLogo />;
    case "Bell":
      return <PhosphorIcons.Bell />;
    case "BellRinging":
      return <PhosphorIcons.BellRinging />;
    case "BellSimple":
      return <PhosphorIcons.BellSimple />;
    case "BellSimpleRinging":
      return <PhosphorIcons.BellSimpleRinging />;
    case "BellSimpleSlash":
      return <PhosphorIcons.BellSimpleSlash />;
    case "BellSimpleZ":
      return <PhosphorIcons.BellSimpleZ />;
    case "BellSlash":
      return <PhosphorIcons.BellSlash />;
    case "BellZ":
      return <PhosphorIcons.BellZ />;
    case "BezierCurve":
      return <PhosphorIcons.BezierCurve />;
    case "Bicycle":
      return <PhosphorIcons.Bicycle />;
    case "Binoculars":
      return <PhosphorIcons.Binoculars />;
    case "Bird":
      return <PhosphorIcons.Bird />;
    case "Bluetooth":
      return <PhosphorIcons.Bluetooth />;
    case "BluetoothConnected":
      return <PhosphorIcons.BluetoothConnected />;
    case "BluetoothSlash":
      return <PhosphorIcons.BluetoothSlash />;
    case "BluetoothX":
      return <PhosphorIcons.BluetoothX />;
    case "Boat":
      return <PhosphorIcons.Boat />;
    case "Book":
      return <PhosphorIcons.Book />;
    case "BookBookmark":
      return <PhosphorIcons.BookBookmark />;
    case "BookOpen":
      return <PhosphorIcons.BookOpen />;
    case "Bookmark":
      return <PhosphorIcons.Bookmark />;
    case "BookmarkSimple":
      return <PhosphorIcons.BookmarkSimple />;
    case "Bookmarks":
      return <PhosphorIcons.Bookmarks />;
    case "BookmarksSimple":
      return <PhosphorIcons.BookmarksSimple />;
    case "Books":
      return <PhosphorIcons.Books />;
    case "BoundingBox":
      return <PhosphorIcons.BoundingBox />;
    case "BracketsAngle":
      return <PhosphorIcons.BracketsAngle />;
    case "BracketsCurly":
      return <PhosphorIcons.BracketsCurly />;
    case "BracketsRound":
      return <PhosphorIcons.BracketsRound />;
    case "BracketsSquare":
      return <PhosphorIcons.BracketsSquare />;
    case "Brain":
      return <PhosphorIcons.Brain />;
    case "Brandy":
      return <PhosphorIcons.Brandy />;
    case "Briefcase":
      return <PhosphorIcons.Briefcase />;
    case "BriefcaseMetal":
      return <PhosphorIcons.BriefcaseMetal />;
    case "Broadcast":
      return <PhosphorIcons.Broadcast />;
    case "Browser":
      return <PhosphorIcons.Browser />;
    case "Browsers":
      return <PhosphorIcons.Browsers />;
    case "Bug":
      return <PhosphorIcons.Bug />;
    case "BugBeetle":
      return <PhosphorIcons.BugBeetle />;
    case "BugDroid":
      return <PhosphorIcons.BugDroid />;
    case "Buildings":
      return <PhosphorIcons.Buildings />;
    case "Bus":
      return <PhosphorIcons.Bus />;
    case "Butterfly":
      return <PhosphorIcons.Butterfly />;
    case "Cactus":
      return <PhosphorIcons.Cactus />;
    case "Cake":
      return <PhosphorIcons.Cake />;
    case "Calculator":
      return <PhosphorIcons.Calculator />;
    case "Calendar":
      return <PhosphorIcons.Calendar />;
    case "CalendarBlank":
      return <PhosphorIcons.CalendarBlank />;
    case "CalendarCheck":
      return <PhosphorIcons.CalendarCheck />;
    case "CalendarPlus":
      return <PhosphorIcons.CalendarPlus />;
    case "CalendarX":
      return <PhosphorIcons.CalendarX />;
    case "Camera":
      return <PhosphorIcons.Camera />;
    case "CameraRotate":
      return <PhosphorIcons.CameraRotate />;
    case "CameraSlash":
      return <PhosphorIcons.CameraSlash />;
    case "Campfire":
      return <PhosphorIcons.Campfire />;
    case "Car":
      return <PhosphorIcons.Car />;
    case "CarSimple":
      return <PhosphorIcons.CarSimple />;
    case "Cardholder":
      return <PhosphorIcons.Cardholder />;
    case "Cards":
      return <PhosphorIcons.Cards />;
    case "CaretCircleDoubleDown":
      return <PhosphorIcons.CaretCircleDoubleDown />;
    case "CaretCircleDoubleLeft":
      return <PhosphorIcons.CaretCircleDoubleLeft />;
    case "CaretCircleDoubleRight":
      return <PhosphorIcons.CaretCircleDoubleRight />;
    case "CaretCircleDoubleUp":
      return <PhosphorIcons.CaretCircleDoubleUp />;
    case "CaretCircleDown":
      return <PhosphorIcons.CaretCircleDown />;
    case "CaretCircleLeft":
      return <PhosphorIcons.CaretCircleLeft />;
    case "CaretCircleRight":
      return <PhosphorIcons.CaretCircleRight />;
    case "CaretCircleUp":
      return <PhosphorIcons.CaretCircleUp />;
    case "CaretDoubleDown":
      return <PhosphorIcons.CaretDoubleDown />;
    case "CaretDoubleLeft":
      return <PhosphorIcons.CaretDoubleLeft />;
    case "CaretDoubleRight":
      return <PhosphorIcons.CaretDoubleRight />;
    case "CaretDoubleUp":
      return <PhosphorIcons.CaretDoubleUp />;
    case "CaretDown":
      return <PhosphorIcons.CaretDown />;
    case "CaretLeft":
      return <PhosphorIcons.CaretLeft />;
    case "CaretRight":
      return <PhosphorIcons.CaretRight />;
    case "CaretUp":
      return <PhosphorIcons.CaretUp />;
    case "Cat":
      return <PhosphorIcons.Cat />;
    case "CellSignalFull":
      return <PhosphorIcons.CellSignalFull />;
    case "CellSignalHigh":
      return <PhosphorIcons.CellSignalHigh />;
    case "CellSignalLow":
      return <PhosphorIcons.CellSignalLow />;
    case "CellSignalMedium":
      return <PhosphorIcons.CellSignalMedium />;
    case "CellSignalNone":
      return <PhosphorIcons.CellSignalNone />;
    case "CellSignalSlash":
      return <PhosphorIcons.CellSignalSlash />;
    case "CellSignalX":
      return <PhosphorIcons.CellSignalX />;
    case "Chalkboard":
      return <PhosphorIcons.Chalkboard />;
    case "ChalkboardSimple":
      return <PhosphorIcons.ChalkboardSimple />;
    case "ChalkboardTeacher":
      return <PhosphorIcons.ChalkboardTeacher />;
    case "ChartBar":
      return <PhosphorIcons.ChartBar />;
    case "ChartBarHorizontal":
      return <PhosphorIcons.ChartBarHorizontal />;
    case "ChartLine":
      return <PhosphorIcons.ChartLine />;
    case "ChartLineUp":
      return <PhosphorIcons.ChartLineUp />;
    case "ChartPie":
      return <PhosphorIcons.ChartPie />;
    case "ChartPieSlice":
      return <PhosphorIcons.ChartPieSlice />;
    case "Chat":
      return <PhosphorIcons.Chat />;
    case "ChatCentered":
      return <PhosphorIcons.ChatCentered />;
    case "ChatCenteredDots":
      return <PhosphorIcons.ChatCenteredDots />;
    case "ChatCenteredText":
      return <PhosphorIcons.ChatCenteredText />;
    case "ChatCircle":
      return <PhosphorIcons.ChatCircle />;
    case "ChatCircleDots":
      return <PhosphorIcons.ChatCircleDots />;
    case "ChatCircleText":
      return <PhosphorIcons.ChatCircleText />;
    case "ChatDots":
      return <PhosphorIcons.ChatDots />;
    case "ChatTeardrop":
      return <PhosphorIcons.ChatTeardrop />;
    case "ChatTeardropDots":
      return <PhosphorIcons.ChatTeardropDots />;
    case "ChatTeardropText":
      return <PhosphorIcons.ChatTeardropText />;
    case "ChatText":
      return <PhosphorIcons.ChatText />;
    case "Chats":
      return <PhosphorIcons.Chats />;
    case "ChatsCircle":
      return <PhosphorIcons.ChatsCircle />;
    case "ChatsTeardrop":
      return <PhosphorIcons.ChatsTeardrop />;
    case "Check":
      return <PhosphorIcons.Check />;
    case "CheckCircle":
      return <PhosphorIcons.CheckCircle />;
    case "CheckSquare":
      return <PhosphorIcons.CheckSquare />;
    case "CheckSquareOffset":
      return <PhosphorIcons.CheckSquareOffset />;
    case "Checks":
      return <PhosphorIcons.Checks />;
    case "Circle":
      return <PhosphorIcons.Circle />;
    case "CircleDashed":
      return <PhosphorIcons.CircleDashed />;
    case "CircleHalf":
      return <PhosphorIcons.CircleHalf />;
    case "CircleHalfTilt":
      return <PhosphorIcons.CircleHalfTilt />;
    case "CircleNotch":
      return <PhosphorIcons.CircleNotch />;
    case "CircleWavy":
      return <PhosphorIcons.CircleWavy />;
    case "CircleWavyCheck":
      return <PhosphorIcons.CircleWavyCheck />;
    case "CircleWavyQuestion":
      return <PhosphorIcons.CircleWavyQuestion />;
    case "CircleWavyWarning":
      return <PhosphorIcons.CircleWavyWarning />;
    case "CirclesFour":
      return <PhosphorIcons.CirclesFour />;
    case "CirclesThree":
      return <PhosphorIcons.CirclesThree />;
    case "CirclesThreePlus":
      return <PhosphorIcons.CirclesThreePlus />;
    case "Clipboard":
      return <PhosphorIcons.Clipboard />;
    case "ClipboardText":
      return <PhosphorIcons.ClipboardText />;
    case "Clock":
      return <PhosphorIcons.Clock />;
    case "ClockAfternoon":
      return <PhosphorIcons.ClockAfternoon />;
    case "ClockClockwise":
      return <PhosphorIcons.ClockClockwise />;
    case "ClockCounterClockwise":
      return <PhosphorIcons.ClockCounterClockwise />;
    case "ClosedCaptioning":
      return <PhosphorIcons.ClosedCaptioning />;
    case "Cloud":
      return <PhosphorIcons.Cloud />;
    case "CloudArrowDown":
      return <PhosphorIcons.CloudArrowDown />;
    case "CloudArrowUp":
      return <PhosphorIcons.CloudArrowUp />;
    case "CloudCheck":
      return <PhosphorIcons.CloudCheck />;
    case "CloudFog":
      return <PhosphorIcons.CloudFog />;
    case "CloudLightning":
      return <PhosphorIcons.CloudLightning />;
    case "CloudMoon":
      return <PhosphorIcons.CloudMoon />;
    case "CloudRain":
      return <PhosphorIcons.CloudRain />;
    case "CloudSlash":
      return <PhosphorIcons.CloudSlash />;
    case "CloudSnow":
      return <PhosphorIcons.CloudSnow />;
    case "CloudSun":
      return <PhosphorIcons.CloudSun />;
    case "Club":
      return <PhosphorIcons.Club />;
    case "CoatHanger":
      return <PhosphorIcons.CoatHanger />;
    case "Code":
      return <PhosphorIcons.Code />;
    case "CodeSimple":
      return <PhosphorIcons.CodeSimple />;
    case "CodepenLogo":
      return <PhosphorIcons.CodepenLogo />;
    case "CodesandboxLogo":
      return <PhosphorIcons.CodesandboxLogo />;
    case "Coffee":
      return <PhosphorIcons.Coffee />;
    case "Coin":
      return <PhosphorIcons.Coin />;
    case "CoinVertical":
      return <PhosphorIcons.CoinVertical />;
    case "Coins":
      return <PhosphorIcons.Coins />;
    case "Columns":
      return <PhosphorIcons.Columns />;
    case "Command":
      return <PhosphorIcons.Command />;
    case "Compass":
      return <PhosphorIcons.Compass />;
    case "ComputerTower":
      return <PhosphorIcons.ComputerTower />;
    case "Confetti":
      return <PhosphorIcons.Confetti />;
    case "Cookie":
      return <PhosphorIcons.Cookie />;
    case "CookingPot":
      return <PhosphorIcons.CookingPot />;
    case "Copy":
      return <PhosphorIcons.Copy />;
    case "CopySimple":
      return <PhosphorIcons.CopySimple />;
    case "Copyleft":
      return <PhosphorIcons.Copyleft />;
    case "Copyright":
      return <PhosphorIcons.Copyright />;
    case "CornersIn":
      return <PhosphorIcons.CornersIn />;
    case "CornersOut":
      return <PhosphorIcons.CornersOut />;
    case "Cpu":
      return <PhosphorIcons.Cpu />;
    case "CreditCard":
      return <PhosphorIcons.CreditCard />;
    case "Crop":
      return <PhosphorIcons.Crop />;
    case "Crosshair":
      return <PhosphorIcons.Crosshair />;
    case "CrosshairSimple":
      return <PhosphorIcons.CrosshairSimple />;
    case "Crown":
      return <PhosphorIcons.Crown />;
    case "CrownSimple":
      return <PhosphorIcons.CrownSimple />;
    case "Cube":
      return <PhosphorIcons.Cube />;
    case "CurrencyBtc":
      return <PhosphorIcons.CurrencyBtc />;
    case "CurrencyCircleDollar":
      return <PhosphorIcons.CurrencyCircleDollar />;
    case "CurrencyCny":
      return <PhosphorIcons.CurrencyCny />;
    case "CurrencyDollar":
      return <PhosphorIcons.CurrencyDollar />;
    case "CurrencyDollarSimple":
      return <PhosphorIcons.CurrencyDollarSimple />;
    case "CurrencyEth":
      return <PhosphorIcons.CurrencyEth />;
    case "CurrencyEur":
      return <PhosphorIcons.CurrencyEur />;
    case "CurrencyGbp":
      return <PhosphorIcons.CurrencyGbp />;
    case "CurrencyInr":
      return <PhosphorIcons.CurrencyInr />;
    case "CurrencyJpy":
      return <PhosphorIcons.CurrencyJpy />;
    case "CurrencyKrw":
      return <PhosphorIcons.CurrencyKrw />;
    case "CurrencyKzt":
      return <PhosphorIcons.CurrencyKzt />;
    case "CurrencyNgn":
      return <PhosphorIcons.CurrencyNgn />;
    case "CurrencyRub":
      return <PhosphorIcons.CurrencyRub />;
    case "Cursor":
      return <PhosphorIcons.Cursor />;
    case "CursorText":
      return <PhosphorIcons.CursorText />;
    case "Cylinder":
      return <PhosphorIcons.Cylinder />;
    case "Database":
      return <PhosphorIcons.Database />;
    case "Desktop":
      return <PhosphorIcons.Desktop />;
    case "DesktopTower":
      return <PhosphorIcons.DesktopTower />;
    case "Detective":
      return <PhosphorIcons.Detective />;
    case "DeviceMobile":
      return <PhosphorIcons.DeviceMobile />;
    case "DeviceMobileCamera":
      return <PhosphorIcons.DeviceMobileCamera />;
    case "DeviceMobileSpeaker":
      return <PhosphorIcons.DeviceMobileSpeaker />;
    case "DeviceTablet":
      return <PhosphorIcons.DeviceTablet />;
    case "DeviceTabletCamera":
      return <PhosphorIcons.DeviceTabletCamera />;
    case "DeviceTabletSpeaker":
      return <PhosphorIcons.DeviceTabletSpeaker />;
    case "Diamond":
      return <PhosphorIcons.Diamond />;
    case "DiamondsFour":
      return <PhosphorIcons.DiamondsFour />;
    case "DiceFive":
      return <PhosphorIcons.DiceFive />;
    case "DiceFour":
      return <PhosphorIcons.DiceFour />;
    case "DiceOne":
      return <PhosphorIcons.DiceOne />;
    case "DiceSix":
      return <PhosphorIcons.DiceSix />;
    case "DiceThree":
      return <PhosphorIcons.DiceThree />;
    case "DiceTwo":
      return <PhosphorIcons.DiceTwo />;
    case "Disc":
      return <PhosphorIcons.Disc />;
    case "DiscordLogo":
      return <PhosphorIcons.DiscordLogo />;
    case "Divide":
      return <PhosphorIcons.Divide />;
    case "Dog":
      return <PhosphorIcons.Dog />;
    case "Door":
      return <PhosphorIcons.Door />;
    case "DotsNine":
      return <PhosphorIcons.DotsNine />;
    case "DotsSix":
      return <PhosphorIcons.DotsSix />;
    case "DotsSixVertical":
      return <PhosphorIcons.DotsSixVertical />;
    case "DotsThree":
      return <PhosphorIcons.DotsThree />;
    case "DotsThreeCircle":
      return <PhosphorIcons.DotsThreeCircle />;
    case "DotsThreeCircleVertical":
      return <PhosphorIcons.DotsThreeCircleVertical />;
    case "DotsThreeOutline":
      return <PhosphorIcons.DotsThreeOutline />;
    case "DotsThreeOutlineVertical":
      return <PhosphorIcons.DotsThreeOutlineVertical />;
    case "DotsThreeVertical":
      return <PhosphorIcons.DotsThreeVertical />;
    case "Download":
      return <PhosphorIcons.Download />;
    case "DownloadSimple":
      return <PhosphorIcons.DownloadSimple />;
    case "DribbbleLogo":
      return <PhosphorIcons.DribbbleLogo />;
    case "Drop":
      return <PhosphorIcons.Drop />;
    case "DropHalf":
      return <PhosphorIcons.DropHalf />;
    case "DropHalfBottom":
      return <PhosphorIcons.DropHalfBottom />;
    case "Ear":
      return <PhosphorIcons.Ear />;
    case "EarSlash":
      return <PhosphorIcons.EarSlash />;
    case "Egg":
      return <PhosphorIcons.Egg />;
    case "EggCrack":
      return <PhosphorIcons.EggCrack />;
    case "Eject":
      return <PhosphorIcons.Eject />;
    case "EjectSimple":
      return <PhosphorIcons.EjectSimple />;
    case "Envelope":
      return <PhosphorIcons.Envelope />;
    case "EnvelopeOpen":
      return <PhosphorIcons.EnvelopeOpen />;
    case "EnvelopeSimple":
      return <PhosphorIcons.EnvelopeSimple />;
    case "EnvelopeSimpleOpen":
      return <PhosphorIcons.EnvelopeSimpleOpen />;
    case "Equalizer":
      return <PhosphorIcons.Equalizer />;
    case "Equals":
      return <PhosphorIcons.Equals />;
    case "Eraser":
      return <PhosphorIcons.Eraser />;
    case "Exam":
      return <PhosphorIcons.Exam />;
    case "Export":
      return <PhosphorIcons.Export />;
    case "Eye":
      return <PhosphorIcons.Eye />;
    case "EyeClosed":
      return <PhosphorIcons.EyeClosed />;
    case "EyeSlash":
      return <PhosphorIcons.EyeSlash />;
    case "Eyedropper":
      return <PhosphorIcons.Eyedropper />;
    case "EyedropperSample":
      return <PhosphorIcons.EyedropperSample />;
    case "Eyeglasses":
      return <PhosphorIcons.Eyeglasses />;
    case "FaceMask":
      return <PhosphorIcons.FaceMask />;
    case "FacebookLogo":
      return <PhosphorIcons.FacebookLogo />;
    case "Factory":
      return <PhosphorIcons.Factory />;
    case "Faders":
      return <PhosphorIcons.Faders />;
    case "FadersHorizontal":
      return <PhosphorIcons.FadersHorizontal />;
    case "FastForward":
      return <PhosphorIcons.FastForward />;
    case "FastForwardCircle":
      return <PhosphorIcons.FastForwardCircle />;
    case "FigmaLogo":
      return <PhosphorIcons.FigmaLogo />;
    case "File":
      return <PhosphorIcons.File />;
    case "FileArrowDown":
      return <PhosphorIcons.FileArrowDown />;
    case "FileArrowUp":
      return <PhosphorIcons.FileArrowUp />;
    case "FileAudio":
      return <PhosphorIcons.FileAudio />;
    case "FileCloud":
      return <PhosphorIcons.FileCloud />;
    case "FileCode":
      return <PhosphorIcons.FileCode />;
    case "FileCss":
      return <PhosphorIcons.FileCss />;
    case "FileCsv":
      return <PhosphorIcons.FileCsv />;
    case "FileDoc":
      return <PhosphorIcons.FileDoc />;
    case "FileDotted":
      return <PhosphorIcons.FileDotted />;
    case "FileHtml":
      return <PhosphorIcons.FileHtml />;
    case "FileImage":
      return <PhosphorIcons.FileImage />;
    case "FileJpg":
      return <PhosphorIcons.FileJpg />;
    case "FileJs":
      return <PhosphorIcons.FileJs />;
    case "FileJsx":
      return <PhosphorIcons.FileJsx />;
    case "FileLock":
      return <PhosphorIcons.FileLock />;
    case "FileMinus":
      return <PhosphorIcons.FileMinus />;
    case "FilePdf":
      return <PhosphorIcons.FilePdf />;
    case "FilePlus":
      return <PhosphorIcons.FilePlus />;
    case "FilePng":
      return <PhosphorIcons.FilePng />;
    case "FilePpt":
      return <PhosphorIcons.FilePpt />;
    case "FileRs":
      return <PhosphorIcons.FileRs />;
    case "FileSearch":
      return <PhosphorIcons.FileSearch />;
    case "FileText":
      return <PhosphorIcons.FileText />;
    case "FileTs":
      return <PhosphorIcons.FileTs />;
    case "FileTsx":
      return <PhosphorIcons.FileTsx />;
    case "FileVideo":
      return <PhosphorIcons.FileVideo />;
    case "FileVue":
      return <PhosphorIcons.FileVue />;
    case "FileX":
      return <PhosphorIcons.FileX />;
    case "FileXls":
      return <PhosphorIcons.FileXls />;
    case "FileZip":
      return <PhosphorIcons.FileZip />;
    case "Files":
      return <PhosphorIcons.Files />;
    case "FilmScript":
      return <PhosphorIcons.FilmScript />;
    case "FilmSlate":
      return <PhosphorIcons.FilmSlate />;
    case "FilmStrip":
      return <PhosphorIcons.FilmStrip />;
    case "Fingerprint":
      return <PhosphorIcons.Fingerprint />;
    case "FingerprintSimple":
      return <PhosphorIcons.FingerprintSimple />;
    case "FinnTheHuman":
      return <PhosphorIcons.FinnTheHuman />;
    case "Fire":
      return <PhosphorIcons.Fire />;
    case "FireSimple":
      return <PhosphorIcons.FireSimple />;
    case "FirstAid":
      return <PhosphorIcons.FirstAid />;
    case "FirstAidKit":
      return <PhosphorIcons.FirstAidKit />;
    case "Fish":
      return <PhosphorIcons.Fish />;
    case "FishSimple":
      return <PhosphorIcons.FishSimple />;
    case "Flag":
      return <PhosphorIcons.Flag />;
    case "FlagBanner":
      return <PhosphorIcons.FlagBanner />;
    case "FlagCheckered":
      return <PhosphorIcons.FlagCheckered />;
    case "Flame":
      return <PhosphorIcons.Flame />;
    case "Flashlight":
      return <PhosphorIcons.Flashlight />;
    case "Flask":
      return <PhosphorIcons.Flask />;
    case "FloppyDisk":
      return <PhosphorIcons.FloppyDisk />;
    case "FloppyDiskBack":
      return <PhosphorIcons.FloppyDiskBack />;
    case "FlowArrow":
      return <PhosphorIcons.FlowArrow />;
    case "Flower":
      return <PhosphorIcons.Flower />;
    case "FlowerLotus":
      return <PhosphorIcons.FlowerLotus />;
    case "FlyingSaucer":
      return <PhosphorIcons.FlyingSaucer />;
    case "Folder":
      return <PhosphorIcons.Folder />;
    case "FolderDotted":
      return <PhosphorIcons.FolderDotted />;
    case "FolderLock":
      return <PhosphorIcons.FolderLock />;
    case "FolderMinus":
      return <PhosphorIcons.FolderMinus />;
    case "FolderNotch":
      return <PhosphorIcons.FolderNotch />;
    case "FolderNotchMinus":
      return <PhosphorIcons.FolderNotchMinus />;
    case "FolderNotchOpen":
      return <PhosphorIcons.FolderNotchOpen />;
    case "FolderNotchPlus":
      return <PhosphorIcons.FolderNotchPlus />;
    case "FolderOpen":
      return <PhosphorIcons.FolderOpen />;
    case "FolderPlus":
      return <PhosphorIcons.FolderPlus />;
    case "FolderSimple":
      return <PhosphorIcons.FolderSimple />;
    case "FolderSimpleDotted":
      return <PhosphorIcons.FolderSimpleDotted />;
    case "FolderSimpleLock":
      return <PhosphorIcons.FolderSimpleLock />;
    case "FolderSimpleMinus":
      return <PhosphorIcons.FolderSimpleMinus />;
    case "FolderSimplePlus":
      return <PhosphorIcons.FolderSimplePlus />;
    case "FolderSimpleStar":
      return <PhosphorIcons.FolderSimpleStar />;
    case "FolderSimpleUser":
      return <PhosphorIcons.FolderSimpleUser />;
    case "FolderStar":
      return <PhosphorIcons.FolderStar />;
    case "FolderUser":
      return <PhosphorIcons.FolderUser />;
    case "Folders":
      return <PhosphorIcons.Folders />;
    case "Football":
      return <PhosphorIcons.Football />;
    case "ForkKnife":
      return <PhosphorIcons.ForkKnife />;
    case "FrameCorners":
      return <PhosphorIcons.FrameCorners />;
    case "FramerLogo":
      return <PhosphorIcons.FramerLogo />;
    case "Function":
      return <PhosphorIcons.Function />;
    case "Funnel":
      return <PhosphorIcons.Funnel />;
    case "FunnelSimple":
      return <PhosphorIcons.FunnelSimple />;
    case "GameController":
      return <PhosphorIcons.GameController />;
    case "GasPump":
      return <PhosphorIcons.GasPump />;
    case "Gauge":
      return <PhosphorIcons.Gauge />;
    case "Gear":
      return <PhosphorIcons.Gear />;
    case "GearSix":
      return <PhosphorIcons.GearSix />;
    case "GenderFemale":
      return <PhosphorIcons.GenderFemale />;
    case "GenderIntersex":
      return <PhosphorIcons.GenderIntersex />;
    case "GenderMale":
      return <PhosphorIcons.GenderMale />;
    case "GenderNeuter":
      return <PhosphorIcons.GenderNeuter />;
    case "GenderNonbinary":
      return <PhosphorIcons.GenderNonbinary />;
    case "GenderTransgender":
      return <PhosphorIcons.GenderTransgender />;
    case "Ghost":
      return <PhosphorIcons.Ghost />;
    case "Gif":
      return <PhosphorIcons.Gif />;
    case "Gift":
      return <PhosphorIcons.Gift />;
    case "GitBranch":
      return <PhosphorIcons.GitBranch />;
    case "GitCommit":
      return <PhosphorIcons.GitCommit />;
    case "GitDiff":
      return <PhosphorIcons.GitDiff />;
    case "GitFork":
      return <PhosphorIcons.GitFork />;
    case "GitMerge":
      return <PhosphorIcons.GitMerge />;
    case "GitPullRequest":
      return <PhosphorIcons.GitPullRequest />;
    case "GithubLogo":
      return <PhosphorIcons.GithubLogo />;
    case "GitlabLogo":
      return <PhosphorIcons.GitlabLogo />;
    case "GitlabLogoSimple":
      return <PhosphorIcons.GitlabLogoSimple />;
    case "Globe":
      return <PhosphorIcons.Globe />;
    case "GlobeHemisphereEast":
      return <PhosphorIcons.GlobeHemisphereEast />;
    case "GlobeHemisphereWest":
      return <PhosphorIcons.GlobeHemisphereWest />;
    case "GlobeSimple":
      return <PhosphorIcons.GlobeSimple />;
    case "GlobeStand":
      return <PhosphorIcons.GlobeStand />;
    case "GoogleChromeLogo":
      return <PhosphorIcons.GoogleChromeLogo />;
    case "GoogleLogo":
      return <PhosphorIcons.GoogleLogo />;
    case "GooglePhotosLogo":
      return <PhosphorIcons.GooglePhotosLogo />;
    case "GooglePlayLogo":
      return <PhosphorIcons.GooglePlayLogo />;
    case "GooglePodcastsLogo":
      return <PhosphorIcons.GooglePodcastsLogo />;
    case "Gradient":
      return <PhosphorIcons.Gradient />;
    case "GraduationCap":
      return <PhosphorIcons.GraduationCap />;
    case "Graph":
      return <PhosphorIcons.Graph />;
    case "GridFour":
      return <PhosphorIcons.GridFour />;
    case "Hamburger":
      return <PhosphorIcons.Hamburger />;
    case "Hand":
      return <PhosphorIcons.Hand />;
    case "HandEye":
      return <PhosphorIcons.HandEye />;
    case "HandFist":
      return <PhosphorIcons.HandFist />;
    case "HandGrabbing":
      return <PhosphorIcons.HandGrabbing />;
    case "HandPalm":
      return <PhosphorIcons.HandPalm />;
    case "HandPointing":
      return <PhosphorIcons.HandPointing />;
    case "HandSoap":
      return <PhosphorIcons.HandSoap />;
    case "HandWaving":
      return <PhosphorIcons.HandWaving />;
    case "Handbag":
      return <PhosphorIcons.Handbag />;
    case "HandbagSimple":
      return <PhosphorIcons.HandbagSimple />;
    case "HandsClapping":
      return <PhosphorIcons.HandsClapping />;
    case "Handshake":
      return <PhosphorIcons.Handshake />;
    case "HardDrive":
      return <PhosphorIcons.HardDrive />;
    case "HardDrives":
      return <PhosphorIcons.HardDrives />;
    case "Hash":
      return <PhosphorIcons.Hash />;
    case "HashStraight":
      return <PhosphorIcons.HashStraight />;
    case "Headlights":
      return <PhosphorIcons.Headlights />;
    case "Headphones":
      return <PhosphorIcons.Headphones />;
    case "Headset":
      return <PhosphorIcons.Headset />;
    case "Heart":
      return <PhosphorIcons.Heart />;
    case "HeartBreak":
      return <PhosphorIcons.HeartBreak />;
    case "HeartStraight":
      return <PhosphorIcons.HeartStraight />;
    case "HeartStraightBreak":
      return <PhosphorIcons.HeartStraightBreak />;
    case "Heartbeat":
      return <PhosphorIcons.Heartbeat />;
    case "Hexagon":
      return <PhosphorIcons.Hexagon />;
    case "HighlighterCircle":
      return <PhosphorIcons.HighlighterCircle />;
    case "Horse":
      return <PhosphorIcons.Horse />;
    case "Hourglass":
      return <PhosphorIcons.Hourglass />;
    case "HourglassHigh":
      return <PhosphorIcons.HourglassHigh />;
    case "HourglassLow":
      return <PhosphorIcons.HourglassLow />;
    case "HourglassMedium":
      return <PhosphorIcons.HourglassMedium />;
    case "HourglassSimple":
      return <PhosphorIcons.HourglassSimple />;
    case "HourglassSimpleHigh":
      return <PhosphorIcons.HourglassSimpleHigh />;
    case "HourglassSimpleLow":
      return <PhosphorIcons.HourglassSimpleLow />;
    case "HourglassSimpleMedium":
      return <PhosphorIcons.HourglassSimpleMedium />;
    case "House":
      return <PhosphorIcons.House />;
    case "HouseLine":
      return <PhosphorIcons.HouseLine />;
    case "HouseSimple":
      return <PhosphorIcons.HouseSimple />;
    case "IdentificationBadge":
      return <PhosphorIcons.IdentificationBadge />;
    case "IdentificationCard":
      return <PhosphorIcons.IdentificationCard />;
    case "Image":
      return <PhosphorIcons.Image />;
    case "ImageSquare":
      return <PhosphorIcons.ImageSquare />;
    case "Infinity":
      return <PhosphorIcons.Infinity />;
    case "Info":
      return <PhosphorIcons.Info />;
    case "InstagramLogo":
      return <PhosphorIcons.InstagramLogo />;
    case "Intersect":
      return <PhosphorIcons.Intersect />;
    case "Jeep":
      return <PhosphorIcons.Jeep />;
    case "Kanban":
      return <PhosphorIcons.Kanban />;
    case "Key":
      return <PhosphorIcons.Key />;
    case "KeyReturn":
      return <PhosphorIcons.KeyReturn />;
    case "Keyboard":
      return <PhosphorIcons.Keyboard />;
    case "Keyhole":
      return <PhosphorIcons.Keyhole />;
    case "Knife":
      return <PhosphorIcons.Knife />;
    case "Ladder":
      return <PhosphorIcons.Ladder />;
    case "LadderSimple":
      return <PhosphorIcons.LadderSimple />;
    case "Lamp":
      return <PhosphorIcons.Lamp />;
    case "Laptop":
      return <PhosphorIcons.Laptop />;
    case "Layout":
      return <PhosphorIcons.Layout />;
    case "Leaf":
      return <PhosphorIcons.Leaf />;
    case "Lifebuoy":
      return <PhosphorIcons.Lifebuoy />;
    case "Lightbulb":
      return <PhosphorIcons.Lightbulb />;
    case "LightbulbFilament":
      return <PhosphorIcons.LightbulbFilament />;
    case "Lightning":
      return <PhosphorIcons.Lightning />;
    case "LightningSlash":
      return <PhosphorIcons.LightningSlash />;
    case "LineSegment":
      return <PhosphorIcons.LineSegment />;
    case "LineSegments":
      return <PhosphorIcons.LineSegments />;
    case "Link":
      return <PhosphorIcons.Link />;
    case "LinkBreak":
      return <PhosphorIcons.LinkBreak />;
    case "LinkSimple":
      return <PhosphorIcons.LinkSimple />;
    case "LinkSimpleBreak":
      return <PhosphorIcons.LinkSimpleBreak />;
    case "LinkSimpleHorizontal":
      return <PhosphorIcons.LinkSimpleHorizontal />;
    case "LinkSimpleHorizontalBreak":
      return <PhosphorIcons.LinkSimpleHorizontalBreak />;
    case "LinkedinLogo":
      return <PhosphorIcons.LinkedinLogo />;
    case "LinuxLogo":
      return <PhosphorIcons.LinuxLogo />;
    case "List":
      return <PhosphorIcons.List />;
    case "ListBullets":
      return <PhosphorIcons.ListBullets />;
    case "ListChecks":
      return <PhosphorIcons.ListChecks />;
    case "ListDashes":
      return <PhosphorIcons.ListDashes />;
    case "ListNumbers":
      return <PhosphorIcons.ListNumbers />;
    case "ListPlus":
      return <PhosphorIcons.ListPlus />;
    case "Lock":
      return <PhosphorIcons.Lock />;
    case "LockKey":
      return <PhosphorIcons.LockKey />;
    case "LockKeyOpen":
      return <PhosphorIcons.LockKeyOpen />;
    case "LockLaminated":
      return <PhosphorIcons.LockLaminated />;
    case "LockLaminatedOpen":
      return <PhosphorIcons.LockLaminatedOpen />;
    case "LockOpen":
      return <PhosphorIcons.LockOpen />;
    case "LockSimple":
      return <PhosphorIcons.LockSimple />;
    case "LockSimpleOpen":
      return <PhosphorIcons.LockSimpleOpen />;
    case "MagicWand":
      return <PhosphorIcons.MagicWand />;
    case "Magnet":
      return <PhosphorIcons.Magnet />;
    case "MagnetStraight":
      return <PhosphorIcons.MagnetStraight />;
    case "MagnifyingGlass":
      return <PhosphorIcons.MagnifyingGlass />;
    case "MagnifyingGlassMinus":
      return <PhosphorIcons.MagnifyingGlassMinus />;
    case "MagnifyingGlassPlus":
      return <PhosphorIcons.MagnifyingGlassPlus />;
    case "MapPin":
      return <PhosphorIcons.MapPin />;
    case "MapPinLine":
      return <PhosphorIcons.MapPinLine />;
    case "MapTrifold":
      return <PhosphorIcons.MapTrifold />;
    case "MarkerCircle":
      return <PhosphorIcons.MarkerCircle />;
    case "Martini":
      return <PhosphorIcons.Martini />;
    case "MaskHappy":
      return <PhosphorIcons.MaskHappy />;
    case "MaskSad":
      return <PhosphorIcons.MaskSad />;
    case "MathOperations":
      return <PhosphorIcons.MathOperations />;
    case "Medal":
      return <PhosphorIcons.Medal />;
    case "MediumLogo":
      return <PhosphorIcons.MediumLogo />;
    case "Megaphone":
      return <PhosphorIcons.Megaphone />;
    case "MegaphoneSimple":
      return <PhosphorIcons.MegaphoneSimple />;
    case "MessengerLogo":
      return <PhosphorIcons.MessengerLogo />;
    case "Microphone":
      return <PhosphorIcons.Microphone />;
    case "MicrophoneSlash":
      return <PhosphorIcons.MicrophoneSlash />;
    case "MicrophoneStage":
      return <PhosphorIcons.MicrophoneStage />;
    case "MicrosoftExcelLogo":
      return <PhosphorIcons.MicrosoftExcelLogo />;
    case "MicrosoftPowerpointLogo":
      return <PhosphorIcons.MicrosoftPowerpointLogo />;
    case "MicrosoftTeamsLogo":
      return <PhosphorIcons.MicrosoftTeamsLogo />;
    case "MicrosoftWordLogo":
      return <PhosphorIcons.MicrosoftWordLogo />;
    case "Minus":
      return <PhosphorIcons.Minus />;
    case "MinusCircle":
      return <PhosphorIcons.MinusCircle />;
    case "Money":
      return <PhosphorIcons.Money />;
    case "Monitor":
      return <PhosphorIcons.Monitor />;
    case "MonitorPlay":
      return <PhosphorIcons.MonitorPlay />;
    case "Moon":
      return <PhosphorIcons.Moon />;
    case "MoonStars":
      return <PhosphorIcons.MoonStars />;
    case "Mountains":
      return <PhosphorIcons.Mountains />;
    case "Mouse":
      return <PhosphorIcons.Mouse />;
    case "MouseSimple":
      return <PhosphorIcons.MouseSimple />;
    case "MusicNote":
      return <PhosphorIcons.MusicNote />;
    case "MusicNoteSimple":
      return <PhosphorIcons.MusicNoteSimple />;
    case "MusicNotes":
      return <PhosphorIcons.MusicNotes />;
    case "MusicNotesPlus":
      return <PhosphorIcons.MusicNotesPlus />;
    case "MusicNotesSimple":
      return <PhosphorIcons.MusicNotesSimple />;
    case "NavigationArrow":
      return <PhosphorIcons.NavigationArrow />;
    case "Needle":
      return <PhosphorIcons.Needle />;
    case "Newspaper":
      return <PhosphorIcons.Newspaper />;
    case "NewspaperClipping":
      return <PhosphorIcons.NewspaperClipping />;
    case "Note":
      return <PhosphorIcons.Note />;
    case "NoteBlank":
      return <PhosphorIcons.NoteBlank />;
    case "NotePencil":
      return <PhosphorIcons.NotePencil />;
    case "Notebook":
      return <PhosphorIcons.Notebook />;
    case "Notepad":
      return <PhosphorIcons.Notepad />;
    case "Notification":
      return <PhosphorIcons.Notification />;
    case "NumberCircleEight":
      return <PhosphorIcons.NumberCircleEight />;
    case "NumberCircleFive":
      return <PhosphorIcons.NumberCircleFive />;
    case "NumberCircleFour":
      return <PhosphorIcons.NumberCircleFour />;
    case "NumberCircleNine":
      return <PhosphorIcons.NumberCircleNine />;
    case "NumberCircleOne":
      return <PhosphorIcons.NumberCircleOne />;
    case "NumberCircleSeven":
      return <PhosphorIcons.NumberCircleSeven />;
    case "NumberCircleSix":
      return <PhosphorIcons.NumberCircleSix />;
    case "NumberCircleThree":
      return <PhosphorIcons.NumberCircleThree />;
    case "NumberCircleTwo":
      return <PhosphorIcons.NumberCircleTwo />;
    case "NumberCircleZero":
      return <PhosphorIcons.NumberCircleZero />;
    case "NumberEight":
      return <PhosphorIcons.NumberEight />;
    case "NumberFive":
      return <PhosphorIcons.NumberFive />;
    case "NumberFour":
      return <PhosphorIcons.NumberFour />;
    case "NumberNine":
      return <PhosphorIcons.NumberNine />;
    case "NumberOne":
      return <PhosphorIcons.NumberOne />;
    case "NumberSeven":
      return <PhosphorIcons.NumberSeven />;
    case "NumberSix":
      return <PhosphorIcons.NumberSix />;
    case "NumberSquareEight":
      return <PhosphorIcons.NumberSquareEight />;
    case "NumberSquareFive":
      return <PhosphorIcons.NumberSquareFive />;
    case "NumberSquareFour":
      return <PhosphorIcons.NumberSquareFour />;
    case "NumberSquareNine":
      return <PhosphorIcons.NumberSquareNine />;
    case "NumberSquareOne":
      return <PhosphorIcons.NumberSquareOne />;
    case "NumberSquareSeven":
      return <PhosphorIcons.NumberSquareSeven />;
    case "NumberSquareSix":
      return <PhosphorIcons.NumberSquareSix />;
    case "NumberSquareThree":
      return <PhosphorIcons.NumberSquareThree />;
    case "NumberSquareTwo":
      return <PhosphorIcons.NumberSquareTwo />;
    case "NumberSquareZero":
      return <PhosphorIcons.NumberSquareZero />;
    case "NumberThree":
      return <PhosphorIcons.NumberThree />;
    case "NumberTwo":
      return <PhosphorIcons.NumberTwo />;
    case "NumberZero":
      return <PhosphorIcons.NumberZero />;
    case "Nut":
      return <PhosphorIcons.Nut />;
    case "NyTimesLogo":
      return <PhosphorIcons.NyTimesLogo />;
    case "Octagon":
      return <PhosphorIcons.Octagon />;
    case "Option":
      return <PhosphorIcons.Option />;
    case "Package":
      return <PhosphorIcons.Package />;
    case "PaintBrush":
      return <PhosphorIcons.PaintBrush />;
    case "PaintBrushBroad":
      return <PhosphorIcons.PaintBrushBroad />;
    case "PaintBrushHousehold":
      return <PhosphorIcons.PaintBrushHousehold />;
    case "PaintBucket":
      return <PhosphorIcons.PaintBucket />;
    case "PaintRoller":
      return <PhosphorIcons.PaintRoller />;
    case "Palette":
      return <PhosphorIcons.Palette />;
    case "PaperPlane":
      return <PhosphorIcons.PaperPlane />;
    case "PaperPlaneRight":
      return <PhosphorIcons.PaperPlaneRight />;
    case "PaperPlaneTilt":
      return <PhosphorIcons.PaperPlaneTilt />;
    case "Paperclip":
      return <PhosphorIcons.Paperclip />;
    case "PaperclipHorizontal":
      return <PhosphorIcons.PaperclipHorizontal />;
    case "Parachute":
      return <PhosphorIcons.Parachute />;
    case "Password":
      return <PhosphorIcons.Password />;
    case "Path":
      return <PhosphorIcons.Path />;
    case "Pause":
      return <PhosphorIcons.Pause />;
    case "PauseCircle":
      return <PhosphorIcons.PauseCircle />;
    case "PawPrint":
      return <PhosphorIcons.PawPrint />;
    case "Peace":
      return <PhosphorIcons.Peace />;
    case "Pen":
      return <PhosphorIcons.Pen />;
    case "PenNib":
      return <PhosphorIcons.PenNib />;
    case "PenNibStraight":
      return <PhosphorIcons.PenNibStraight />;
    case "Pencil":
      return <PhosphorIcons.Pencil />;
    case "PencilCircle":
      return <PhosphorIcons.PencilCircle />;
    case "PencilLine":
      return <PhosphorIcons.PencilLine />;
    case "PencilSimple":
      return <PhosphorIcons.PencilSimple />;
    case "PencilSimpleLine":
      return <PhosphorIcons.PencilSimpleLine />;
    case "Percent":
      return <PhosphorIcons.Percent />;
    case "Person":
      return <PhosphorIcons.Person />;
    case "PersonSimple":
      return <PhosphorIcons.PersonSimple />;
    case "PersonSimpleRun":
      return <PhosphorIcons.PersonSimpleRun />;
    case "PersonSimpleWalk":
      return <PhosphorIcons.PersonSimpleWalk />;
    case "Perspective":
      return <PhosphorIcons.Perspective />;
    case "Phone":
      return <PhosphorIcons.Phone />;
    case "PhoneCall":
      return <PhosphorIcons.PhoneCall />;
    case "PhoneDisconnect":
      return <PhosphorIcons.PhoneDisconnect />;
    case "PhoneIncoming":
      return <PhosphorIcons.PhoneIncoming />;
    case "PhoneOutgoing":
      return <PhosphorIcons.PhoneOutgoing />;
    case "PhoneSlash":
      return <PhosphorIcons.PhoneSlash />;
    case "PhoneX":
      return <PhosphorIcons.PhoneX />;
    case "PhosphorLogo":
      return <PhosphorIcons.PhosphorLogo />;
    case "PianoKeys":
      return <PhosphorIcons.PianoKeys />;
    case "PictureInPicture":
      return <PhosphorIcons.PictureInPicture />;
    case "Pill":
      return <PhosphorIcons.Pill />;
    case "PinterestLogo":
      return <PhosphorIcons.PinterestLogo />;
    case "Pinwheel":
      return <PhosphorIcons.Pinwheel />;
    case "Pizza":
      return <PhosphorIcons.Pizza />;
    case "Placeholder":
      return <PhosphorIcons.Placeholder />;
    case "Planet":
      return <PhosphorIcons.Planet />;
    case "Play":
      return <PhosphorIcons.Play />;
    case "PlayCircle":
      return <PhosphorIcons.PlayCircle />;
    case "Playlist":
      return <PhosphorIcons.Playlist />;
    case "Plug":
      return <PhosphorIcons.Plug />;
    case "Plugs":
      return <PhosphorIcons.Plugs />;
    case "PlugsConnected":
      return <PhosphorIcons.PlugsConnected />;
    case "Plus":
      return <PhosphorIcons.Plus />;
    case "PlusCircle":
      return <PhosphorIcons.PlusCircle />;
    case "PlusMinus":
      return <PhosphorIcons.PlusMinus />;
    case "PokerChip":
      return <PhosphorIcons.PokerChip />;
    case "PoliceCar":
      return <PhosphorIcons.PoliceCar />;
    case "Polygon":
      return <PhosphorIcons.Polygon />;
    case "Popcorn":
      return <PhosphorIcons.Popcorn />;
    case "Power":
      return <PhosphorIcons.Power />;
    case "Prescription":
      return <PhosphorIcons.Prescription />;
    case "Presentation":
      return <PhosphorIcons.Presentation />;
    case "PresentationChart":
      return <PhosphorIcons.PresentationChart />;
    case "Printer":
      return <PhosphorIcons.Printer />;
    case "Prohibit":
      return <PhosphorIcons.Prohibit />;
    case "ProhibitInset":
      return <PhosphorIcons.ProhibitInset />;
    case "ProjectorScreen":
      return <PhosphorIcons.ProjectorScreen />;
    case "ProjectorScreenChart":
      return <PhosphorIcons.ProjectorScreenChart />;
    case "PushPin":
      return <PhosphorIcons.PushPin />;
    case "PushPinSimple":
      return <PhosphorIcons.PushPinSimple />;
    case "PushPinSimpleSlash":
      return <PhosphorIcons.PushPinSimpleSlash />;
    case "PushPinSlash":
      return <PhosphorIcons.PushPinSlash />;
    case "PuzzlePiece":
      return <PhosphorIcons.PuzzlePiece />;
    case "QrCode":
      return <PhosphorIcons.QrCode />;
    case "Question":
      return <PhosphorIcons.Question />;
    case "Queue":
      return <PhosphorIcons.Queue />;
    case "Quotes":
      return <PhosphorIcons.Quotes />;
    case "Radical":
      return <PhosphorIcons.Radical />;
    case "Radio":
      return <PhosphorIcons.Radio />;
    case "RadioButton":
      return <PhosphorIcons.RadioButton />;
    case "Rainbow":
      return <PhosphorIcons.Rainbow />;
    case "RainbowCloud":
      return <PhosphorIcons.RainbowCloud />;
    case "Receipt":
      return <PhosphorIcons.Receipt />;
    case "Record":
      return <PhosphorIcons.Record />;
    case "Rectangle":
      return <PhosphorIcons.Rectangle />;
    case "Recycle":
      return <PhosphorIcons.Recycle />;
    case "RedditLogo":
      return <PhosphorIcons.RedditLogo />;
    case "Repeat":
      return <PhosphorIcons.Repeat />;
    case "RepeatOnce":
      return <PhosphorIcons.RepeatOnce />;
    case "Rewind":
      return <PhosphorIcons.Rewind />;
    case "RewindCircle":
      return <PhosphorIcons.RewindCircle />;
    case "Robot":
      return <PhosphorIcons.Robot />;
    case "Rocket":
      return <PhosphorIcons.Rocket />;
    case "RocketLaunch":
      return <PhosphorIcons.RocketLaunch />;
    case "Rows":
      return <PhosphorIcons.Rows />;
    case "Rss":
      return <PhosphorIcons.Rss />;
    case "RssSimple":
      return <PhosphorIcons.RssSimple />;
    case "Rug":
      return <PhosphorIcons.Rug />;
    case "Ruler":
      return <PhosphorIcons.Ruler />;
    case "Scales":
      return <PhosphorIcons.Scales />;
    case "Scan":
      return <PhosphorIcons.Scan />;
    case "Scissors":
      return <PhosphorIcons.Scissors />;
    case "Screencast":
      return <PhosphorIcons.Screencast />;
    case "ScribbleLoop":
      return <PhosphorIcons.ScribbleLoop />;
    case "Scroll":
      return <PhosphorIcons.Scroll />;
    case "Selection":
      return <PhosphorIcons.Selection />;
    case "SelectionAll":
      return <PhosphorIcons.SelectionAll />;
    case "SelectionBackground":
      return <PhosphorIcons.SelectionBackground />;
    case "SelectionForeground":
      return <PhosphorIcons.SelectionForeground />;
    case "SelectionInverse":
      return <PhosphorIcons.SelectionInverse />;
    case "SelectionPlus":
      return <PhosphorIcons.SelectionPlus />;
    case "SelectionSlash":
      return <PhosphorIcons.SelectionSlash />;
    case "Share":
      return <PhosphorIcons.Share />;
    case "ShareNetwork":
      return <PhosphorIcons.ShareNetwork />;
    case "Shield":
      return <PhosphorIcons.Shield />;
    case "ShieldCheck":
      return <PhosphorIcons.ShieldCheck />;
    case "ShieldCheckered":
      return <PhosphorIcons.ShieldCheckered />;
    case "ShieldChevron":
      return <PhosphorIcons.ShieldChevron />;
    case "ShieldPlus":
      return <PhosphorIcons.ShieldPlus />;
    case "ShieldSlash":
      return <PhosphorIcons.ShieldSlash />;
    case "ShieldStar":
      return <PhosphorIcons.ShieldStar />;
    case "ShieldWarning":
      return <PhosphorIcons.ShieldWarning />;
    case "ShoppingBag":
      return <PhosphorIcons.ShoppingBag />;
    case "ShoppingBagOpen":
      return <PhosphorIcons.ShoppingBagOpen />;
    case "ShoppingCart":
      return <PhosphorIcons.ShoppingCart />;
    case "ShoppingCartSimple":
      return <PhosphorIcons.ShoppingCartSimple />;
    case "Shower":
      return <PhosphorIcons.Shower />;
    case "Shuffle":
      return <PhosphorIcons.Shuffle />;
    case "ShuffleAngular":
      return <PhosphorIcons.ShuffleAngular />;
    case "ShuffleSimple":
      return <PhosphorIcons.ShuffleSimple />;
    case "Sidebar":
      return <PhosphorIcons.Sidebar />;
    case "SidebarSimple":
      return <PhosphorIcons.SidebarSimple />;
    case "SignIn":
      return <PhosphorIcons.SignIn />;
    case "SignOut":
      return <PhosphorIcons.SignOut />;
    case "Signpost":
      return <PhosphorIcons.Signpost />;
    case "SimCard":
      return <PhosphorIcons.SimCard />;
    case "SketchLogo":
      return <PhosphorIcons.SketchLogo />;
    case "SkipBack":
      return <PhosphorIcons.SkipBack />;
    case "SkipBackCircle":
      return <PhosphorIcons.SkipBackCircle />;
    case "SkipForward":
      return <PhosphorIcons.SkipForward />;
    case "SkipForwardCircle":
      return <PhosphorIcons.SkipForwardCircle />;
    case "Skull":
      return <PhosphorIcons.Skull />;
    case "SlackLogo":
      return <PhosphorIcons.SlackLogo />;
    case "Sliders":
      return <PhosphorIcons.Sliders />;
    case "SlidersHorizontal":
      return <PhosphorIcons.SlidersHorizontal />;
    case "Smiley":
      return <PhosphorIcons.Smiley />;
    case "SmileyBlank":
      return <PhosphorIcons.SmileyBlank />;
    case "SmileyMeh":
      return <PhosphorIcons.SmileyMeh />;
    case "SmileyNervous":
      return <PhosphorIcons.SmileyNervous />;
    case "SmileySad":
      return <PhosphorIcons.SmileySad />;
    case "SmileySticker":
      return <PhosphorIcons.SmileySticker />;
    case "SmileyWink":
      return <PhosphorIcons.SmileyWink />;
    case "SmileyXEyes":
      return <PhosphorIcons.SmileyXEyes />;
    case "SnapchatLogo":
      return <PhosphorIcons.SnapchatLogo />;
    case "Snowflake":
      return <PhosphorIcons.Snowflake />;
    case "SoccerBall":
      return <PhosphorIcons.SoccerBall />;
    case "SortAscending":
      return <PhosphorIcons.SortAscending />;
    case "SortDescending":
      return <PhosphorIcons.SortDescending />;
    case "Spade":
      return <PhosphorIcons.Spade />;
    case "Sparkle":
      return <PhosphorIcons.Sparkle />;
    case "SpeakerHigh":
      return <PhosphorIcons.SpeakerHigh />;
    case "SpeakerLow":
      return <PhosphorIcons.SpeakerLow />;
    case "SpeakerNone":
      return <PhosphorIcons.SpeakerNone />;
    case "SpeakerSimpleHigh":
      return <PhosphorIcons.SpeakerSimpleHigh />;
    case "SpeakerSimpleLow":
      return <PhosphorIcons.SpeakerSimpleLow />;
    case "SpeakerSimpleNone":
      return <PhosphorIcons.SpeakerSimpleNone />;
    case "SpeakerSimpleSlash":
      return <PhosphorIcons.SpeakerSimpleSlash />;
    case "SpeakerSimpleX":
      return <PhosphorIcons.SpeakerSimpleX />;
    case "SpeakerSlash":
      return <PhosphorIcons.SpeakerSlash />;
    case "SpeakerX":
      return <PhosphorIcons.SpeakerX />;
    case "Spinner":
      return <PhosphorIcons.Spinner />;
    case "SpinnerGap":
      return <PhosphorIcons.SpinnerGap />;
    case "Spiral":
      return <PhosphorIcons.Spiral />;
    case "SpotifyLogo":
      return <PhosphorIcons.SpotifyLogo />;
    case "Square":
      return <PhosphorIcons.Square />;
    case "SquareHalf":
      return <PhosphorIcons.SquareHalf />;
    case "SquareHalfBottom":
      return <PhosphorIcons.SquareHalfBottom />;
    case "SquareLogo":
      return <PhosphorIcons.SquareLogo />;
    case "SquaresFour":
      return <PhosphorIcons.SquaresFour />;
    case "Stack":
      return <PhosphorIcons.Stack />;
    case "StackOverflowLogo":
      return <PhosphorIcons.StackOverflowLogo />;
    case "StackSimple":
      return <PhosphorIcons.StackSimple />;
    case "Stamp":
      return <PhosphorIcons.Stamp />;
    case "Star":
      return <PhosphorIcons.Star />;
    case "StarFour":
      return <PhosphorIcons.StarFour />;
    case "StarHalf":
      return <PhosphorIcons.StarHalf />;
    case "Sticker":
      return <PhosphorIcons.Sticker />;
    case "Stop":
      return <PhosphorIcons.Stop />;
    case "StopCircle":
      return <PhosphorIcons.StopCircle />;
    case "Storefront":
      return <PhosphorIcons.Storefront />;
    case "Strategy":
      return <PhosphorIcons.Strategy />;
    case "StripeLogo":
      return <PhosphorIcons.StripeLogo />;
    case "Student":
      return <PhosphorIcons.Student />;
    case "Suitcase":
      return <PhosphorIcons.Suitcase />;
    case "SuitcaseSimple":
      return <PhosphorIcons.SuitcaseSimple />;
    case "Sun":
      return <PhosphorIcons.Sun />;
    case "SunDim":
      return <PhosphorIcons.SunDim />;
    case "SunHorizon":
      return <PhosphorIcons.SunHorizon />;
    case "Sunglasses":
      return <PhosphorIcons.Sunglasses />;
    case "Swap":
      return <PhosphorIcons.Swap />;
    case "Swatches":
      return <PhosphorIcons.Swatches />;
    case "Sword":
      return <PhosphorIcons.Sword />;
    case "Syringe":
      return <PhosphorIcons.Syringe />;
    case "TShirt":
      return <PhosphorIcons.TShirt />;
    case "Table":
      return <PhosphorIcons.Table />;
    case "Tabs":
      return <PhosphorIcons.Tabs />;
    case "Tag":
      return <PhosphorIcons.Tag />;
    case "TagChevron":
      return <PhosphorIcons.TagChevron />;
    case "TagSimple":
      return <PhosphorIcons.TagSimple />;
    case "Target":
      return <PhosphorIcons.Target />;
    case "Taxi":
      return <PhosphorIcons.Taxi />;
    case "TelegramLogo":
      return <PhosphorIcons.TelegramLogo />;
    case "Television":
      return <PhosphorIcons.Television />;
    case "TelevisionSimple":
      return <PhosphorIcons.TelevisionSimple />;
    case "TennisBall":
      return <PhosphorIcons.TennisBall />;
    case "Terminal":
      return <PhosphorIcons.Terminal />;
    case "TerminalWindow":
      return <PhosphorIcons.TerminalWindow />;
    case "TestTube":
      return <PhosphorIcons.TestTube />;
    case "TextAa":
      return <PhosphorIcons.TextAa />;
    case "TextAlignCenter":
      return <PhosphorIcons.TextAlignCenter />;
    case "TextAlignJustify":
      return <PhosphorIcons.TextAlignJustify />;
    case "TextAlignLeft":
      return <PhosphorIcons.TextAlignLeft />;
    case "TextAlignRight":
      return <PhosphorIcons.TextAlignRight />;
    case "TextBolder":
      return <PhosphorIcons.TextBolder />;
    case "TextH":
      return <PhosphorIcons.TextH />;
    case "TextHFive":
      return <PhosphorIcons.TextHFive />;
    case "TextHFour":
      return <PhosphorIcons.TextHFour />;
    case "TextHOne":
      return <PhosphorIcons.TextHOne />;
    case "TextHSix":
      return <PhosphorIcons.TextHSix />;
    case "TextHThree":
      return <PhosphorIcons.TextHThree />;
    case "TextHTwo":
      return <PhosphorIcons.TextHTwo />;
    case "TextIndent":
      return <PhosphorIcons.TextIndent />;
    case "TextItalic":
      return <PhosphorIcons.TextItalic />;
    case "TextOutdent":
      return <PhosphorIcons.TextOutdent />;
    case "TextStrikethrough":
      return <PhosphorIcons.TextStrikethrough />;
    case "TextT":
      return <PhosphorIcons.TextT />;
    case "TextUnderline":
      return <PhosphorIcons.TextUnderline />;
    case "Textbox":
      return <PhosphorIcons.Textbox />;
    case "Thermometer":
      return <PhosphorIcons.Thermometer />;
    case "ThermometerCold":
      return <PhosphorIcons.ThermometerCold />;
    case "ThermometerHot":
      return <PhosphorIcons.ThermometerHot />;
    case "ThermometerSimple":
      return <PhosphorIcons.ThermometerSimple />;
    case "ThumbsDown":
      return <PhosphorIcons.ThumbsDown />;
    case "ThumbsUp":
      return <PhosphorIcons.ThumbsUp />;
    case "Ticket":
      return <PhosphorIcons.Ticket />;
    case "TiktokLogo":
      return <PhosphorIcons.TiktokLogo />;
    case "Timer":
      return <PhosphorIcons.Timer />;
    case "ToggleLeft":
      return <PhosphorIcons.ToggleLeft />;
    case "ToggleRight":
      return <PhosphorIcons.ToggleRight />;
    case "Toilet":
      return <PhosphorIcons.Toilet />;
    case "ToiletPaper":
      return <PhosphorIcons.ToiletPaper />;
    case "Tote":
      return <PhosphorIcons.Tote />;
    case "ToteSimple":
      return <PhosphorIcons.ToteSimple />;
    case "TrademarkRegistered":
      return <PhosphorIcons.TrademarkRegistered />;
    case "TrafficCone":
      return <PhosphorIcons.TrafficCone />;
    case "TrafficSign":
      return <PhosphorIcons.TrafficSign />;
    case "TrafficSignal":
      return <PhosphorIcons.TrafficSignal />;
    case "Train":
      return <PhosphorIcons.Train />;
    case "TrainRegional":
      return <PhosphorIcons.TrainRegional />;
    case "TrainSimple":
      return <PhosphorIcons.TrainSimple />;
    case "Translate":
      return <PhosphorIcons.Translate />;
    case "Trash":
      return <PhosphorIcons.Trash />;
    case "TrashSimple":
      return <PhosphorIcons.TrashSimple />;
    case "Tray":
      return <PhosphorIcons.Tray />;
    case "Tree":
      return <PhosphorIcons.Tree />;
    case "TreeEvergreen":
      return <PhosphorIcons.TreeEvergreen />;
    case "TreeStructure":
      return <PhosphorIcons.TreeStructure />;
    case "TrendDown":
      return <PhosphorIcons.TrendDown />;
    case "TrendUp":
      return <PhosphorIcons.TrendUp />;
    case "Triangle":
      return <PhosphorIcons.Triangle />;
    case "Trophy":
      return <PhosphorIcons.Trophy />;
    case "Truck":
      return <PhosphorIcons.Truck />;
    case "TwitchLogo":
      return <PhosphorIcons.TwitchLogo />;
    case "TwitterLogo":
      return <PhosphorIcons.TwitterLogo />;
    case "Umbrella":
      return <PhosphorIcons.Umbrella />;
    case "UmbrellaSimple":
      return <PhosphorIcons.UmbrellaSimple />;
    case "Upload":
      return <PhosphorIcons.Upload />;
    case "UploadSimple":
      return <PhosphorIcons.UploadSimple />;
    case "User":
      return <PhosphorIcons.User />;
    case "UserCircle":
      return <PhosphorIcons.UserCircle />;
    case "UserCircleGear":
      return <PhosphorIcons.UserCircleGear />;
    case "UserCircleMinus":
      return <PhosphorIcons.UserCircleMinus />;
    case "UserCirclePlus":
      return <PhosphorIcons.UserCirclePlus />;
    case "UserFocus":
      return <PhosphorIcons.UserFocus />;
    case "UserGear":
      return <PhosphorIcons.UserGear />;
    case "UserList":
      return <PhosphorIcons.UserList />;
    case "UserMinus":
      return <PhosphorIcons.UserMinus />;
    case "UserPlus":
      return <PhosphorIcons.UserPlus />;
    case "UserRectangle":
      return <PhosphorIcons.UserRectangle />;
    case "UserSquare":
      return <PhosphorIcons.UserSquare />;
    case "UserSwitch":
      return <PhosphorIcons.UserSwitch />;
    case "Users":
      return <PhosphorIcons.Users />;
    case "UsersFour":
      return <PhosphorIcons.UsersFour />;
    case "UsersThree":
      return <PhosphorIcons.UsersThree />;
    case "Vault":
      return <PhosphorIcons.Vault />;
    case "Vibrate":
      return <PhosphorIcons.Vibrate />;
    case "VideoCamera":
      return <PhosphorIcons.VideoCamera />;
    case "VideoCameraSlash":
      return <PhosphorIcons.VideoCameraSlash />;
    case "Vignette":
      return <PhosphorIcons.Vignette />;
    case "Voicemail":
      return <PhosphorIcons.Voicemail />;
    case "Volleyball":
      return <PhosphorIcons.Volleyball />;
    case "Wall":
      return <PhosphorIcons.Wall />;
    case "Wallet":
      return <PhosphorIcons.Wallet />;
    case "Warning":
      return <PhosphorIcons.Warning />;
    case "WarningCircle":
      return <PhosphorIcons.WarningCircle />;
    case "WarningOctagon":
      return <PhosphorIcons.WarningOctagon />;
    case "Watch":
      return <PhosphorIcons.Watch />;
    case "WaveSawtooth":
      return <PhosphorIcons.WaveSawtooth />;
    case "WaveSine":
      return <PhosphorIcons.WaveSine />;
    case "WaveSquare":
      return <PhosphorIcons.WaveSquare />;
    case "WaveTriangle":
      return <PhosphorIcons.WaveTriangle />;
    case "Waves":
      return <PhosphorIcons.Waves />;
    case "Webcam":
      return <PhosphorIcons.Webcam />;
    case "WhatsappLogo":
      return <PhosphorIcons.WhatsappLogo />;
    case "Wheelchair":
      return <PhosphorIcons.Wheelchair />;
    case "WifiHigh":
      return <PhosphorIcons.WifiHigh />;
    case "WifiLow":
      return <PhosphorIcons.WifiLow />;
    case "WifiMedium":
      return <PhosphorIcons.WifiMedium />;
    case "WifiNone":
      return <PhosphorIcons.WifiNone />;
    case "WifiSlash":
      return <PhosphorIcons.WifiSlash />;
    case "WifiX":
      return <PhosphorIcons.WifiX />;
    case "Wind":
      return <PhosphorIcons.Wind />;
    case "WindowsLogo":
      return <PhosphorIcons.WindowsLogo />;
    case "Wine":
      return <PhosphorIcons.Wine />;
    case "Wrench":
      return <PhosphorIcons.Wrench />;
    case "X":
      return <PhosphorIcons.X />;
    case "XCircle":
      return <PhosphorIcons.XCircle />;
    case "XSquare":
      return <PhosphorIcons.XSquare />;
    case "YinYang":
      return <PhosphorIcons.YinYang />;
    case "YoutubeLogo":
      return <PhosphorIcons.YoutubeLogo />;
    default:
      return <PhosphorIcons.Smiley />;
  }
}

export default FindIcon;
