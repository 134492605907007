import React from "react";

const Loading = () => {
  return (
    <div className="loading__screen">
      <div className="sp sp-wave"></div>
    </div>
  );
};

export default Loading;
