import React, { useState } from "react";
import { useCloseRef } from "../../hooks/useCloseRef";
import { ICheckPasswordRes } from "../../react-query/actions/useAuth";
import { axios } from "../../utils/axiosLib";
import { useAppStore } from "../../store/store";
import cookies from "js-cookie";
import Logo from "../../images/logo.png";

interface IProps {
  closePopUp: () => void;
}

const LoginPopup: React.FC<IProps> = ({ closePopUp }: IProps) => {
  const [state, setState] = useState<any>({ email: "", password: "" });
  const [error, showError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [ref] = useCloseRef(closePopUp);
  const handleStateChange = (e: any) => setState((state: any) => ({ ...state, [e.target.name]: e.target.value }));

  const { setSessionActive } = useAppStore();

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    try {
      setLoading(true);
      showError(false);
      const { data } = (await axios.post(
        `${process.env.REACT_APP_AUTH_V1}/v1/login`,
        {
          email: state.email,
          password: state.password,
        },
        {
          withCredentials: true,
        }
      )) as { data: ICheckPasswordRes };

      const accessToken = cookies.get("pxhub_access_token") as string;

      axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;

      setSessionActive(true);
      setLoading(false);
      closePopUp();
    } catch (error) {
      setLoading(false);
      showError(true);
    }
  };

  return (
    <div className="overlay__container">
      <div className="login__container">
        <div ref={ref} className="login__form__container">
          <div className="logo row center-xs mb-24">
            <img src={Logo} alt="The People Experience Hub" />
          </div>
          <form onSubmit={handleSubmit}>
            <div className="heading">
              <h4>Welcome, Sign into your account</h4>
            </div>

            {error ? <p className="error">Invalid credentials, please try again</p> : null}
            {loading ? (
              <div className="row center-xs">
                <div className="loading__small">
                  <div className="loading"></div>
                </div>
              </div>
            ) : (
              <>
                <label htmlFor="email">
                  Email:
                  <input type="text" name="email" onChange={handleStateChange} placeholder="e.g. michael@pxhub.io" />
                </label>
                <label htmlFor="password">
                  Password:
                  <input type="password" name="password" onChange={handleStateChange} placeholder="**********" />
                </label>
                <input type="submit" name="submit" value="Login" disabled={!state.email.length && !state.password.length} />
              </>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default LoginPopup;
