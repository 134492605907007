import { StateCreator } from "zustand";

export interface ISessionSlice {
  session: {
    isSessionActive: boolean;
    isLoading: boolean;
    isPending: boolean;
  };

  setSessionActive: (bool: boolean) => void;
  setPending: (bool: boolean) => void;
  resetAuth: () => void;
}

const initialState = {
  isSessionActive: false,
  isLoading: false,
  isPending: false,
};

export const createSessionSlice: StateCreator<ISessionSlice, [], [], ISessionSlice> = (set, get) => ({
  session: {
    ...initialState,
  },

  setSessionActive: (bool: boolean) =>
    set((state) => ({
      session: {
        ...state.session,
        isSessionActive: bool,
      },
    })),
  setPending: (bool: boolean) =>
    set((state) => ({
      session: {
        ...state.session,
        isPending: bool,
      },
    })),
  resetAuth: () =>
    set(() => ({
      session: { ...initialState },
    })),
});
